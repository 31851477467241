import './Footer.scss'
import Logo from 'static/images/footer_logo.png'

const Footer = (props) => {
    return (
        <footer className='footer'>
            <div className='info'>
                <img src={Logo} alt="SICT"/>
                <div className="info-wrap">
                    <div>
                        <span>INFORMATION</span>
                        <ul>
                            <li className="after-line">(주)에스아이시티</li>
                        </ul>
                    </div>
                    <div>
                        <span>LOCATION</span>
                        <ul>
                            <li>{'서울특별시 강남구\n강남대로146길 25 (논현동) 3층'}</li>
                        </ul>
                    </div>
                    <div className="contact">
                        <span>CONTACT</span>
                        <ul>
                            <li className="after-line">T. 02-6931-3360</li>
                            <li className="after-line">F. 02-6931-3370</li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="copyright_mo" >COPYRIGHTⓒ SICT. ALL RIGHTS RESERVED. Icons by <a href='https://icons8.com/' target='_blank' rel="noopener noreferrer">Icons8</a></p>
        </footer>
    )
}

export default Footer