import { useEffect, useState } from 'react'
import BackDoubleIco from 'static/images/back-double.png'
import BackIco from 'static/images/back.png'
import ForwardDoubleIco from 'static/images/forward-double.png'
import ForwardIco from 'static/images/forward.png'
import './UserPage.scss'

const UserPage = (props) => {
    const [totalPage, setTotalPage] = useState(1) // 전체페이지
    const [curPage, setCurPage] = useState(1) // 현재페이지
    const [userList, setUserList] = useState([]) // 유저정보 리스트

    useEffect(() => {
        // 최초 진입시 유저 정보 불러오기
        getUserDefault()
    },[])

    useEffect(() => {
        getUserList(curPage)
    }, [curPage])

    // 초기 진입시 페이징 처리
    const getUserDefault = async() => {
        try {
            const result = await props.util.callApi.Get('/admin/user', {page: 1})
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const userList = result.data
            setTotalPage(userList.totalPage)
        } catch(error) {
            console.error(error)
        }
    }

    // 페이지 이동시 페이징 처리
    const getUserList = async(page) => {
        try {
            const result = await props.util.callApi.Get('/admin/user', {page: page})
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const userList = result.data
            const dataList = userList.dataList
            setUserList(dataList)
        } catch(error) {
            console.error(error)
        }
    }

    // 페이징을 위한 페이지 수 만큼 만들기
    const setPaging = () => {
        let pages = []
        const pageCnt = props.isMobile ? 5 : 10 // 모바일일 경우 5페이지씩, 데스크탑일경우 10페이지씩
        const cnt = (Math.ceil(curPage/pageCnt) - 1) * pageCnt + 1
        for(let i=cnt; i<cnt+pageCnt; i++) {
            if(totalPage < i) {
                break;
            }
            pages.push(
                <a key={`paging${i}`} className={`pages ${i === curPage ? 'select' : ''}`} href='!#' onClick={(e) => {e.preventDefault(); setCurPage(i)}}>{i}</a>
            )
        }
        return pages
    }

    // 이전 버튼 클릭
    const movePrev = () => {
        const page = curPage > 1 ? curPage - 1 : 1
        setCurPage(page)
    }
    const moveFisrt = () => {
        setCurPage(1)
    }
    // 다음 버튼 클릭
    const moveForward = () => {
        const page = curPage < totalPage ? curPage + 1 : totalPage
        setCurPage(page)
    }
    const moveLast = () => {
        setCurPage(totalPage)
    }

    return (
        <article className='user-page'>
            <p className='title'>사용자 목록</p>
            <div className='table-area'>
                <table className='user-table'>
                    <tbody className='user-table-body'>
                        <tr>
                            <th className='longlong'>이메일(계정)</th>
                            <th className='short'>사용자명</th>
                            <th className='long'>회사</th>
                            <th className='long'>직종</th>
                            <th className='normal'>전화번호</th>
                            <th className='normal'>가입일</th>
                            <th className='short'>권한</th>
                        </tr>
                        {
                            userList.map((e,i) => (
                                <tr key={`userListRow${i}`}>
                                    <td>{e.userEmail}</td>
                                    <td>{e.userNm}</td>
                                    <td>{e.userCoNm}</td>
                                    <td>{e.userJobs}</td>
                                    <td>{e.phoneNum}</td>
                                    <td>{e.insertDt.substring(0,10)}</td>
                                    <td>{e.userRole}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='paging-area'>
                <img className='btn' alt='back' onClick={()=>{moveFisrt()}} src={BackDoubleIco}/>
                <img className='btn' alt='back' onClick={()=>{movePrev()}} src={BackIco}/>
                <div className='pages-area'>
                {
                    setPaging()
                }
                </div>
                <img className='btn' alt='back' onClick={()=>{moveForward()}} src={ForwardIco}/>
                <img className='btn' alt='back' onClick={()=>{moveLast()}} src={ForwardDoubleIco}/>
            </div>
        </article>
    )
}

export default UserPage