import Layout from 'component/layout/Layout';
import './App.scss';
import { useEffect } from 'react';

function App() {
    // 화면 세로 실제 크기 지정을 위한 함수
    const setScreenSize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        setScreenSize();
        window.addEventListener('resize', () => setScreenSize());
    }, [])
    
    return (
        <Layout/>
    );
}

export default App;
