import { useEffect, useState } from 'react'
import './MyPage.scss'
import SubscribeCard from './SubscribeCard/SubscribeCard'
import { useNavigate, useParams } from 'react-router-dom'

const MyPage = (props) => {
    const params = useParams(); // URL에 담겨진 파라미터 꺼내는 Hook
    const navigate = useNavigate()
    const [selected, setSelected] = useState('subscribe') // 탭 선택 값
    const [subscribes, setSubscribes] = useState([]) // 구독 목록
    const [userInfo, setUserInfo] = useState({ // 사용자 정보
        userEmail: '', // 계정
        userNm: '', // 이름
        userCoNm: '', // 회사명
        userJobs: '', // 직종
        phoneNum: '' // 전화번호
    })

    // 메뉴 변경시 데이터 조회
    useEffect(() => {
        setSelected(params.menuNm)
        if(params.menuNm === 'subscribe') { // 내 구독 목록
            getSubscribes()
            // window.location.reload()
        }
        else if(params.menuNm === 'account') { // 계정 설정
            getUserInfo()
        }
    }, [params.menuNm])

    // 구독목록 호출
    const getSubscribes = async() => {
        try {
            const subscribes = await props.util.callApi.Get('/user/content')
            setSubscribes(subscribes.data)
        } catch(error) {
            console.error(error)
        }
    }
    // 사용자 정보 호출
    const getUserInfo = async() => { 
        try {
            const userInfo = await props.util.callApi.Get('/user', {})
            setUserInfo({
                userEmail: userInfo.data.userEmail, // 계정
                userNm: userInfo.data.userNm, // 이름
                userCoNm: userInfo.data.userCoNm, // 회사명
                userJobs: userInfo.data.userJobs, // 직종
                phoneNum: setPhoneNum(userInfo.data.phoneNum) // 전화번호
            })
        } catch(error) {
            console.error(error)
        }
    }
    // 전화번호 서식 변경
    const setPhoneNum = (num) => {
        if(num.length === 10) {
            return `${num.substring(0,3)}-${num.substring(3,6)}-${num.substring(6,10)}`
        }
        return `${num.substring(0,3)}-${num.substring(3,7)}-${num.substring(7,11)}`
    }

    // 탭 클릭시 메뉴 이동
    const changeMenu = (e, menu) => {
        e.preventDefault(); 
        navigate(`/myPage/${menu}`)
    }

    return (
        <article>
        <nav className='menu-tree'>
            <div className={`menu-item ${selected === 'subscribe' ? 'selected' : ''}`}>
                <a 
                    href='#!'
                    onClick={(e) => {
                        changeMenu(e, 'subscribe')
                    }}>
                    내 구독 목록
                </a>
            </div>
            <div className={`menu-item ${selected === 'account' ? 'selected' : ''}`}>
                <a 
                    href='#!' 
                    onClick={(e) => {
                        changeMenu(e, 'account')
                    }}>
                    계정 설정
                </a>
            </div>
        </nav>
        <div className='my-page-area'>
            <div className='menu-content'>
                {/* 내 구독 목록 */}
                {
                    selected === 'subscribe' &&
                    <>
                    <p className='subscribe-title'>구독 목록</p>
                    <div className='subscribe'>
                        {
                            !!subscribes && subscribes.length > 0 ?
                            subscribes.map((e, i) => (
                                <SubscribeCard 
                                    util={props.util}
                                    key={`subscribeCard${i}`}
                                    title={e.contentNm} 
                                    description={e.contentSimpleDescription}
                                    keyword={e.subscribeKeyword || ''}
                                    contentSeq={e.contentSeq}
                                    subscribeSeq={e.subscribeSeq}/>
                            ))
                            :
                            <div className='none-subscribe'> 
                                <p>구독한 목록이 없습니다</p>
                            </div>
                        }
                    </div>
                    </>
                }
                {/* 계정 설정 */}
                {
                    selected === 'account' &&
                    <div className='account'>
                        <p className='title'>회원 정보</p>
                        <table className='user-info-table'>
                            <tbody>
                                <tr className='user-info-row'>
                                    <th>계정 (이메일)</th>
                                    <td>{userInfo.userEmail}</td>
                                </tr>
                                <tr className='user-info-row'>
                                    <th>이름</th>
                                    <td>{userInfo.userNm}</td>
                                </tr>
                                <tr className='user-info-row'>
                                    <th>회사명</th>
                                    <td>{userInfo.userCoNm}</td>
                                </tr>
                                <tr className='user-info-row'>
                                    <th>직종</th>
                                    <td>{userInfo.userJobs}</td>
                                </tr>
                                <tr className='user-info-row'>
                                    <th>전화번호</th>
                                    <td>{userInfo.phoneNum}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='modify-btn-area'>
                            <button className='modify-btn' onClick={(e) => {changeMenu(e, 'modify')}}>회원 정보 수정</button>
                        </div>
                    </div>
                }
            </div>
        </div>
        </article>
    )
}

export default MyPage