import { useEffect, useState } from 'react'
import BackDoubleIco from 'static/images/back-double.png'
import BackIco from 'static/images/back.png'
import ForwardDoubleIco from 'static/images/forward-double.png'
import ForwardIco from 'static/images/forward.png'
import './OfferBoard.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from 'component/layout/footer/Footer'
import OfferBoardBtn from './button/OfferBoardBtn'
import Constants from './Constants/Constants'

const OfferBoard = (props) => {
    const param = useParams()
    const navigate = useNavigate()
    const [totalPage, setTotalPage] = useState(1) // 전체 페이지 수
    const [curPage, setCurPage] = useState(1) // 현재 페이지 수
    const [items, setItems] = useState([]) // 게시글 리스트

    useEffect(() => {
        // 최초 진입시 유저 정보 불러오기
        getItemsDefault()
    },[])
    useEffect(() => {
        const paramPage = param.page || 1
        window.scrollTo({top:0})
        getItems(paramPage)
        setCurPage(Number(paramPage))
    },[param.page])

    // 초기 진입시 페이징 처리
    const getItemsDefault = async() => {
        try {
            const result = await props.util.callApi.Get('/board/offer', {page: 1})
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const items = result.data
            setTotalPage(items.totalPage)
        } catch(error) {
            console.error(error)
        }
    }

    // 페이지 이동시 페이징 처리
    const getItems = async(page) => {
        try {
            const result = await props.util.callApi.Get('/board/offer', {page: page})
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const items = result.data
            const dataList = items.dataList
            setItems(dataList)
        } catch(error) {
            console.error(error)
        }
    }

    // 페이징을 위한 페이지 수 만큼 만들기
    const setPaging = () => {
        let pages = []
        const pageCnt = props.isMobile ? 5 : 10
        const cnt = (Math.ceil(curPage/pageCnt) - 1) * pageCnt + 1
        for(let i=cnt; i<cnt+pageCnt; i++) {
            if(totalPage < i) {
                break;
            }
            pages.push(
                <a 
                    key={`paging${i}`} 
                    className={`pages ${i === curPage ? 'select' : ''}`} 
                    href={i} 
                    onClick={(e) => {
                        e.preventDefault(); 
                        // setCurPage(i)
                        navigate(`/offer-board/${i}`)
                    }}>{i}</a>
            )
        }
        return pages
    }

    // 이전 버튼 클릭
    const movePrev = () => {
        const page = curPage > 1 ? curPage - 1 : 1
        // setCurPage(page)
        navigate(`/offer-board/${page}`)
    }
    const moveFisrt = () => {
        // setCurPage(1)
        navigate(`/offer-board/1`)
    }
    // 다음 버튼 클릭
    const moveForward = () => {
        const page = curPage < totalPage ? curPage + 1 : totalPage
        // setCurPage(page)
        navigate(`/offer-board/${page}`)
    }
    const moveLast = () => {
        // setCurPage(totalPage)
        navigate(`/offer-board/${totalPage}`)
    }

    // 게시판 상세 페이지 이동
    const moveDetail = (e, seq) => {
        e.preventDefault();
        navigate(`/offer-board/content/${seq}`)
    }

    return (
        <article className='offer-board-page'>
            <div className="intro" >
                <strong className='title'> 기능제안 게시판 </strong>
                <p className='description'>
                    필요한 자동화 서비스 기능을 제안해주세요.<br/>
                    다른사람이 제안한 기능을 평가(추천/비추천) 해주세요.<br/>
                    추천이 많이 된 제안은 실제 기능으로 개발됩니다.
                </p>
            </div>
            <div className='offer-board-area'>
                <OfferBoardBtn 
                    onClick={(e) => {navigate('/offer-board/edit')}}
                >
                    제안하기
                </OfferBoardBtn>
                {
                    props.isMobile ?
                    <div className='offer-items-m'>
                        {
                        items.map((e, i) => (
                            <div key={`boardItem${i}`} className='offer-item-m' onClick={(evt) => {moveDetail(evt, e.boardSeq)}}>
                                <p className='title'>{`[${Constants.getTypeLabel(e.boardType)}]${e.boardTitle}`}<span>{e.replyCnt > 0 && `[${e.replyCnt}]`}</span></p>
                                <div className='info'>
                                    <p>{e.boardWriterNm}</p>
                                    <p>{e.dt}</p>
                                    <p>{`조회${e.boardViewCnt}`}</p>
                                    <p>{`추천${e.boardGoodCnt}`}</p>
                                    <p>{`비추천${e.boardBadCnt}`}</p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                    :
                    <table>
                        <colgroup>
                            <col width={60}/>
                            <col width={100}/>
                            <col />
                            <col width={120}/>
                            <col width={120}/>
                            <col width={60}/>
                            <col width={60}/>
                            <col width={60}/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>번호</th>
                                <th>기능</th>
                                <th style={{textAlign: 'left'}}>제목</th>
                                <th>작성자</th>
                                <th>작성일</th>
                                <th>조회</th>
                                <th>추천</th>
                                <th>비추천</th>
                            </tr>
                            {
                            items.map((e,i) => (
                                <tr 
                                    key={`board-item${i}`} 
                                    className='board-item' 
                                    onClick={(evt) => {
                                        moveDetail(evt, e.boardSeq)
                                }}>
                                    <td>{e.boardSeq}</td>
                                    <td>{Constants.getTypeLabel(e.boardType)}</td>
                                    <td 
                                        className={'board-title'} 
                                        style={{textAlign: 'left'}} 
                                        >
                                            {e.boardTitle} <span style={{fontSize: '1.4rem', color: '#5f5f5f'}}>{!!e.replyCnt ? `[${e.replyCnt}]` : ''}</span>
                                    </td>
                                    <td>{e.boardWriterNm}</td>
                                    <td>{e.dt}</td>
                                    <td>{e.boardViewCnt}</td>
                                    <td>{e.boardGoodCnt}</td>
                                    <td>{e.boardBadCnt}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                }
                <div className='paging-area'>
                    <img className='btn' alt='back' onClick={()=>{moveFisrt()}} src={BackDoubleIco}/>
                    <img className='btn' alt='back' onClick={()=>{movePrev()}} src={BackIco}/>
                    <div className='pages-area'>
                    {
                        setPaging()
                    }
                    </div>
                    <img className='btn' alt='back' onClick={()=>{moveForward()}} src={ForwardIco}/>
                    <img className='btn' alt='back' onClick={()=>{moveLast()}} src={ForwardDoubleIco}/>
                </div>
            </div>
            <Footer />
        </article>
    )
}

export default OfferBoard