const Constants = {
    contentTypeList: [
        {value: 'infoService', labelText: '정보서비스'},
        {value: 'workAutomation', labelText: '업무자동화'},
        {value: 'etc', labelText: '기타'}
    ],
    getTypeLabel: (value) => {
        if(!value) {
            return ''
        }
        const type = Constants.contentTypeList.find((e) => (e.value === value))
        if(!type?.labelText) {
            return ''
        }
        return type.labelText
    }
}

export default Constants