import { FontSize } from "./fontSize";
/**
 * React Quill Custom Toolbar
 */
export const CustomToolbar = () => (
    <div id="toolbar">
        <span className="ql-formats">
            {/* <select className="ql-size" defaultValue="medium">
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
                <option value="huge">Huge</option>
            </select> */}
            <select className="ql-size" defaultValue={'12pt'} title="글씨 크기">
                {FontSize.map((val) => (
                    <option key={`${val}`} value={val} >
                        {val.replace(/[^0-9]/g, "") + 'pt'}
                    </option>
                ))}
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" title="굵게"/>
            <button className="ql-italic" title="기울임"/>
            <button className="ql-underline" title="밑줄"/>
            <button className="ql-strike" title="취소선"/>
            {/* <button className="ql-blockquote" /> */}
        </span>
        <span className="ql-formats">
            <select className="ql-color" title="글자색"/>
            <select className="ql-background" title="글자 배경색"/>
        </span>
        {/* <span className="ql-formats">
            <button className="ql-image" />
            <button className="ql-video" />
        </span> */}
        <span className="ql-formats">
            <button className="ql-clean" title="스타일 제거"/>
        </span>
    </div>
);