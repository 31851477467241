import { useCookies } from 'react-cookie';
import './SignUp.scss'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CUSTextField } from 'component/CUSComponent';
import { SHA256 } from 'crypto-js';

const SignUp = (props) => {
    const navigate = useNavigate()
    const [cookies] = useCookies(['sessionUser']); // 쿠키 관리 Hook

    // 회원가입 양식 값
    const [signForm, setSignForm] = useState({
        userEmail: '', // 이메일
        userPw: '', // 비밀번호
        userPwRe: '', // 비밀번호 확인
        userNm: '', // 이름
        userCoNm: '', // 회사명
        userJobs: '', // 직종
        phoneNum: '' // 전화번호
    })
    const [pwCk, setPwCk] = useState(true) // 비밀번호, 비밀번호 재입력 비교 state (true: nomal/correct, false:wrong)
    const [pwRegCk, setPwRegCk] = useState(true)
    const [message, setMessage] = useState('') // 오류 메세지

    // 로그인한 사용자가 진입시 메인페이지로 이동
    useEffect(() => {
        if(!!cookies.sessionUser?.sessionToken) {
            document.location.replace('/')
        }
    }, [])

    // 회원가입 비밀번호 확인과 맞는지 검증
    useEffect(() => {
        let reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/
        if(!!signForm.userPw && !reg.test(signForm.userPw)) {
            setPwRegCk(false)
            setMessage('비밀번호 규칙이 일치하지 않습니다')
            return;
        }
        if(!!signForm.userPw && !!signForm.userPwRe) {
            if(signForm.userPw !== signForm.userPwRe) {
                setPwCk(false)
                setMessage('비밀번호 확인이 일치하지 않습니다')
                return;
            }
        }
        setPwRegCk(true)
        setPwCk(true)
        setMessage('')
    }, [signForm.userPw, signForm.userPwRe])

    // 회원가입 전송
    const signUp = async(e) => {
        e.preventDefault()
        if(!pwCk || !pwRegCk) {
            return
        }
        const hash = SHA256(signForm.userPw).toString(); //비밀번호 SHA256 방식으로 암호화
        const param = {
            userEmail: signForm.userEmail ,
            userPw: hash,
            userNm: signForm.userNm,
            userCoNm: signForm.userCoNm ,
            userJobs: signForm.userJobs,
            phoneNum: signForm.phoneNum.replaceAll('-', '')
        }
        try{
            const result = await props.util.callApi.Post('/user', param)
            if(!!result.data?.status) {
                setMessage(result.data.errorMessage || '오류가 발생하였습니다.')
                return
            }
            window.alert(`가입되었습니다.`)
            navigate('/login', {replace: true})
        } catch(error) {
            console.error(error)
        }
        
    }

    // 회원가입 양식 작성시 state 변경 함수
    const changeFormData = (value, key) => {
        let temp = {...signForm}
        temp[key] = value;
        setSignForm(temp)
    }


    // 핸드폰 자동 하이픈
    const autoHypenPhone = (str) =>{
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if( str.length < 4){
            return str;
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        }else{              
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }

    return (
        <article>
            <form autoComplete='new-password' className='sign-up-area' onSubmit={(e) => {signUp(e)}}>
                <strong className='title'>{'Welcome\nBOT Service'}</strong>
                <strong className='description'>회원가입</strong>
                <div className='message-area'>
                    <p className='message'>{message}</p>
                </div>
                <CUSTextField 
                    required 
                    placeholder='이메일'
                    type='email'
                    value={signForm.userEmail}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userEmail')
                    }}

                    style={{
                        marginBottom: '0.4rem'
                    }}
                />
                <p className='password-info'>* 영문 숫자 특수기호 조합 8~16자리</p>
                <CUSTextField 
                    required 
                    placeholder='비밀번호'
                    type='password'
                    value={signForm.userPw}
                    style={!pwRegCk ? {border: '2px solid red'} : {}}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userPw')
                    }}
                />
                <CUSTextField 
                    required 
                    placeholder='비밀번호 확인'
                    type='password'
                    value={signForm.userPwRe}
                    style={!pwCk ? {border: '2px solid red'} : {}}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userPwRe')
                    }}
                />
                <CUSTextField 
                    required 
                    placeholder='성명'
                    type='text'
                    value={signForm.userNm}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userNm')
                    }}
                />
                <CUSTextField 
                    required 
                    placeholder='회사명'
                    type='text'
                    value={signForm.userCoNm}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userCoNm')
                    }}
                />
                <CUSTextField 
                    required 
                    placeholder='직종'
                    type='text'
                    value={signForm.userJobs}
                    onChange={(e) => {
                        changeFormData(e.target.value, 'userJobs')
                    }}
                />
                <CUSTextField 
                    required 
                    placeholder='전화번호'
                    value={signForm.phoneNum}
                    type='tel'
                    maxLength={13}
                    onChange={(e) => {
                        e.target.value = autoHypenPhone(e.target.value)
                        changeFormData(e.target.value, 'phoneNum')
                    }}
                />
                <button className='btn'>가입하기</button>
            </form>
        </article>
    )
}

export default SignUp