import { CUSBoxItem, CUSBoxTile, CUSFileInput } from 'component/CUSComponent'
import './ManageContent.scss'
import { useEffect, useState } from 'react'
import NormalImg from 'static/images/image_error.webp'

const ManageContent = (props) => {
    const [contents, setContents] = useState([]) // 컨텐츠 목록
    const [editting, setEditting] = useState(false) // 수정모드 여부
    // 컨텐츠 정보
    const [contentInfo, setContentInfo] = useState({
        contentSeq: '', // 컨텐츠 시퀀스
        contentCd: '',
        contentNm: '', // 컨텐츠 이름
        contentSimpleDescription: '', // 컨텐츠 간단 정보
        contentThumbnailImgPath: '', // 컨텐츠 썸네일 이미지 기존 경로
        contentThumbnailImg: '', // 컨텐츠 썸네일 이미지 파일
        contentDetailDescription: '', // 컨텐츠 상세 정보
        contentExplain: '', // 컨텐츠 서비스 설명
        contentExplainImgPath: '', // 컨텐츠 서비스 설명 이미지 기존 경로
        contentExplainImg: '', // 컨텐츠 서비스 설명 이미지 파일
        contentRes: '', // 컨텐츠 서비스 결과
        contentResImgPath: '', // 컨텐츠 서비스 결과 이미지 기존 경로
        contentResImg: '', // 컨텐츠 서비스 결과 이미지 파일
        contentKeywordYn: 'N', // 키워드 사용 여부
        contentKeywordDescription: '' // 키워드 설명
    })
    // 이미지 원본 저장 (이미지 수정 취소시 롤백 위함)
    const [originImgs, setOriginImgs] = useState({
        contentThumbnailImg: '',
        contentExplainImg: '',
        contentResImg: ''
    })
    // 보여질 이미지
    const [showImg, setShowImg] = useState({
        contentThumbnailImg: '',
        contentExplainImg: '',
        contentResImg: ''
    })

    // 최초진입시 컨텐츠 목록 조회
    useEffect(() => {
        if(!!editting) {
            window.scrollTo({top:0})
        } else {
            getContents()
        }
    }, [editting])

    // 컨텐츠 목록 조회 호출
    const getContents = async() => {
        try {
            const contents = await props.util.callApi.Get('/admin/content', {})
            if(!!contents.data?.status) {
                return
            }

            setContents(contents.data)
        } catch(error) {
            console.error(error)
        }
    }
    
    // 컨텐츠 삭제
    const deleteContent = async(contentSeq) => {
        if(window.confirm('컨텐츠를 삭제하시겠습니까?\n구독중인 사용자의 구독목록에서도 함께 삭제됩니다')) {
            try {
                const result = await props.util.callApi.Delete('/admin/content/' + contentSeq)
                // 커스텀 에러 발생시
                if(!result.data || !!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    return;
                }
                // window.alert('삭제되었습니다')
                getContents()
            } catch(error) {
                console.error(error)
            }
        }
    }

    // 컨텐츠 정보 불러오기
    const getContentDetail = async(contentSeq) => {
        try {
            const content = await props.util.callApi.Get('/content/' + contentSeq)
            // 커스텀 에러 발생시
            if(!content.data || !!content.data?.status) {
                window.alert(content.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            setContentInfo({
                contentSeq: content.data.contentSeq,
                contentCd: content.data.contentCd || '',
                contentNm: content.data.contentNm || '',
                contentSimpleDescription: content.data.contentSimpleDescription || '',
                contentThumbnailImgPath: content.data.contentThumbnailImg || '',
                contentThumbnailImg: content.data.contentThumbnailImg || '',
                contentDetailDescription: content.data.contentDetailDescription || '',
                contentExplain: content.data.contentExplain || '',
                contentExplainImgPath: content.data.contentExplainImg || undefined,
                contentExplainImg: content.data.contentExplainImg || undefined,
                contentRes: content.data.contentRes || '',
                contentResImgPath: content.data.contentResImg || '',
                contentResImg: content.data.contentResImg || '',
                contentKeywordYn:content.data.contentKeywordYn || 'N', 
                contentKeywordDescription: content.data.contentKeywordDescription || '',
            })
            setOriginImgs({
                contentThumbnailImg: content.data.contentThumbnailImg || '',
                contentExplainImg: content.data.contentExplainImg || '',
                contentResImg: content.data.contentResImg || '',
            })
            setShowImg({
                contentThumbnailImg: content.data.contentThumbnailImg || '',
                contentExplainImg: content.data.contentExplainImg || '',
                contentResImg: content.data.contentResImg || '',
            })
        } catch(error) {
            console.error(error)
        }
    }

    // ContentInfo 수정
    const changeContentInfo = (value, key) => {
        let temp = {...contentInfo}
        temp[key] = value
        setContentInfo(temp)
    }

    // 컨텐츠 추가
    const addContent = () => {
        // 초기화
        setContentInfo({
            contentSeq: '', // 컨텐츠 시퀀스
            contentCd: '',
            contentNm: '', // 컨텐츠 이름
            contentSimpleDescription: '', // 컨텐츠 간단 정보
            contentThumbnailImgPath: '', // 컨텐츠 썸네일 이미지 기존 경로
            contentThumbnailImg: '', // 컨텐츠 썸네일 이미지 파일
            contentDetailDescription: '', // 컨텐츠 상세 정보
            contentExplain: '', // 컨텐츠 서비스 설명
            contentExplainImgPath: '', // 컨텐츠 서비스 설명 이미지 기존 경로
            contentExplainImg: '', // 컨텐츠 서비스 설명 이미지 파일
            contentRes: '', // 컨텐츠 서비스 결과
            contentResImgPath: '', // 컨텐츠 서비스 결과 이미지 기존 경로
            contentResImg: '', // 컨텐츠 서비스 결과 이미지 파일
            contentKeywordYn: 'N', // 키워드 사용 여부
            contentKeywordDescription: '',
        })
        setOriginImgs({
            contentThumbnailImg: '',
            contentExplainImg: '',
            contentResImg: ''
        })
        setShowImg({
            contentThumbnailImg: '',
            contentExplainImg: '',
            contentResImg: ''
        })
        setEditting(true)
    }

    // 컨텐츠 수정
    const editContent = (content) => {
        // 초기화
        setContentInfo({
            contentSeq: '', // 컨텐츠 시퀀스
            contentCd: '',
            contentNm: '', // 컨텐츠 이름
            contentSimpleDescription: '', // 컨텐츠 간단 정보
            contentThumbnailImgPath: '', // 컨텐츠 썸네일 이미지 기존 경로
            contentThumbnailImg: '', // 컨텐츠 썸네일 이미지 파일
            contentDetailDescription: '', // 컨텐츠 상세 정보
            contentExplain: '', // 컨텐츠 서비스 설명
            contentExplainImgPath: '', // 컨텐츠 서비스 설명 이미지 기존 경로
            contentExplainImg: '', // 컨텐츠 서비스 설명 이미지 파일
            contentRes: '', // 컨텐츠 서비스 결과
            contentResImgPath: '', // 컨텐츠 서비스 결과 이미지 기존 경로
            contentResImg: '', // 컨텐츠 서비스 결과 이미지 파일
            contentKeywordYn: 'N', // 키워드 사용 여부
            contentKeywordDescription: '',
        })
        setOriginImgs({
            contentThumbnailImg: '',
            contentExplainImg: '',
            contentResImg: ''
        })
        setShowImg({
            contentThumbnailImg: '',
            contentExplainImg: '',
            contentResImg: ''
        })
        getContentDetail(content.contentSeq)
        setEditting(true)
    }

    // 컨텐츠 수정 저장
    const saveEdit = async(e) => {
        if(window.confirm('컨텐츠를 저장하시겠습니까?')) {
            try{
                if(checkImg()) {
                    return;
                }
                const formData = new FormData();
                if(!!contentInfo.contentSeq){
                    formData.append('contentSeq', contentInfo.contentSeq)
                }
                formData.append('contentCd', contentInfo.contentCd)
                formData.append('contentNm', contentInfo.contentNm)
                formData.append('contentSimpleDescription', contentInfo.contentSimpleDescription)
                if(!contentInfo.contentThumbnailImgPath) {
                    formData.append('contentThumbnailImg', contentInfo.contentThumbnailImg)
                }
                formData.append('contentThumbnailImgPath', contentInfo.contentThumbnailImgPath)
                formData.append('contentDetailDescription', contentInfo.contentDetailDescription)
                formData.append('contentExplain', contentInfo.contentExplain)
                if(!contentInfo.contentExplainImgPath) {
                    formData.append('contentExplainImg', contentInfo.contentExplainImg)
                }
                formData.append('contentExplainImgPath', contentInfo.contentExplainImgPath)
                formData.append('contentRes', contentInfo.contentRes)
                if(!contentInfo.contentResImgPath) {
                    formData.append('contentResImg', contentInfo.contentResImg)
                }
                formData.append('contentResImgPath', contentInfo.contentResImgPath)
                formData.append('contentKeywordYn', contentInfo.contentKeywordYn)
                formData.append('contentKeywordDescription', contentInfo.contentKeywordDescription)

                const fileNames = contentInfo.contentThumbnailImg.name + '|' + contentInfo.contentExplainImg.name + '|' + contentInfo.contentResImg.name

                // for(const [key,value] of formData.entries()) {
                //     console.log(key, value)
                // }
                const result = await props.util.callApi.Post(`/admin/content/${contentInfo.contentSeq}`, formData, fileNames)
                if(!!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    return
                }
                // window.alert('저장되었습니다.')
                setEditting(false)
                
            } catch(error) {
                console.error(error)
            }
            
        }
    }
    // 컨텐츠 수정 취소
    const cancelEdit = (e) => {
        if(window.confirm('수정한 내역이 저장되지 않습니다.\n취소하시겠습니까?')) {
            setEditting(false)
        }
    }

    // 컨텐츠 저장/수정 시 이미지 있는지 여부 체크
    const checkImg = () => {
        if(!contentInfo.contentThumbnailImg && !contentInfo.contentThumbnailImgPath) {
            window.alert('썸네일 이미지가 없습니다.')
            return true
        }
        if(!contentInfo.contentExplainImg && !contentInfo.contentExplainImgPath) {
            window.alert('서비스 설명 이미지가 없습니다.')
            return true
        }
        if(!contentInfo.contentResImg && !contentInfo.contentResImgPath) {
            window.alert('서비스 결과 이미지가 없습니다.')
            return true
        }

        return false;
    }

    // 이미지 파일 조작
    const changeImg = (e, key) => {
        const file = e?.target?.files[0];
        if(!!file) { // 파일 업로드시
            let temp = {...contentInfo}
            temp[key] = file
            temp[`${key}Path`] = ''
            setContentInfo(temp)

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let temp = {...showImg}
                temp[key] = reader.result
                setShowImg(temp)
            };
        } else { // 제거 or 파일 취소 시
            let temp = {...contentInfo}
            temp[key] = ''
            temp[`${key}Path`] = originImgs[key]
            setContentInfo(temp)

            let temp2 = {...showImg}
            temp2[key] = originImgs[key]
            setShowImg(temp2)

            e.value=''
        }
    }

    // 이미지 error 발생 시 대체 이미지로 이미지 설정
    const onErrorImg = (e) => {
        e.target.src = NormalImg
    }

    return (
        <article className='manage-cotent-page'>
        {
            editting ?
            <div className='edit-page'>
                <div className='submit'>
                    <button onClick={(e) => {cancelEdit(e)}}>취소</button>
                    <button className='save' onClick={(e) => {saveEdit(e)}}>저장</button>
                </div>
                <div className='thumb'>
                    <CUSBoxItem
                        key={`contents${contentInfo.contentSeq}`} 
                        title={contentInfo.contentNm} 
                        thumbnailImg={showImg.contentThumbnailImg}
                        description={contentInfo.contentSimpleDescription} 
                    />
                    <div className='edit-area'>
                        <label htmlFor='contentThumbnailImg'>썸네일 이미지</label>
                        <CUSFileInput id='contentThumbnailImg' accept = "image/*" onRemove={(e) => {changeImg(e, 'contentThumbnailImg')}} onChange={(e) => {changeImg(e, 'contentThumbnailImg')}}/>
                        <label htmlFor='contentCd'>서비스 코드</label>
                        <input id='contentCd' value={contentInfo.contentCd} onChange={(e) => {changeContentInfo(e.target.value, 'contentCd')}}/>
                        <label htmlFor='contentNm'>서비스명</label>
                        <input id='contentNm' value={contentInfo.contentNm} onChange={(e) => {changeContentInfo(e.target.value, 'contentNm')}}/>
                        <label htmlFor='contentSimpleDescription'>서비스 썸네일 설명</label>
                        <input id='contentSimpleDescription' value={contentInfo.contentSimpleDescription} onChange={(e) => {changeContentInfo(e.target.value, 'contentSimpleDescription')}}/>
                        <label htmlFor='contentDetailDescription'>서비스 상세 설명</label>
                        <input id='contentDetailDescription' value={contentInfo.contentDetailDescription} onChange={(e) => {changeContentInfo(e.target.value, 'contentDetailDescription')}}/>
                    </div>
                </div>
                <div className='main'>
                {
                    props.isMobile ? 
                    <>
                    {/* 모바일 레이아웃 */}
                    <div className='info'style={{paddingBlock:'2rem', marginBottom: '2rem'}}>
                        <div className='info-text'>
                            <div className='title'>
                                <strong>서비스 설명</strong>
                            </div>
                        </div>
                        <div className='info-img' style={{margin: '2rem 0'}} >
                            <img alt="info-img" src={showImg.contentExplainImg} onError={onErrorImg}/>
                            {/* <input id='contentExplainImg' type='file' accept = "image/*" onChange={(e) => {changeImg(e, 'contentExplainImg')}}/> */}
                            <CUSFileInput width={'90%'} id='contentExplainImg' accept = "image/*" onRemove={(e) => {changeImg(e, 'contentExplainImg')}} onChange={(e) => {changeImg(e, 'contentExplainImg')}}/>
                        </div>
                        <div className='info-text input'>
                            <div className='title'>
                                <p>{`${contentInfo.contentExplain.length}/1000`}</p>
                            </div>
                            <textarea value={contentInfo.contentExplain} maxLength={1000} onChange={(e) => {changeContentInfo(e.target.value, 'contentExplain')}} />
                        </div>
                    </div>
                    <div className='result'>
                        <div className='result-text' >
                            <div className='title'>
                                <strong>서비스 결과</strong>
                            </div>
                        </div>
                        <div className='result-img' style={{margin: '2rem 0'}}>
                            <img alt="result-img" src={showImg.contentResImg} onError={onErrorImg}/>
                            <CUSFileInput width={'90%'} id='contentResImg' accept = "image/*" onRemove={(e) => {changeImg(e, 'contentResImg')}} onChange={(e) => {changeImg(e, 'contentResImg')}}/>
                        </div>
                        <div  className='result-text input'>
                            <div className='title'>
                                <p>{`${contentInfo.contentRes.length}/1000`}</p>
                            </div>
                            <textarea value={contentInfo.contentRes} maxLength={1000} onChange={(e) => {changeContentInfo(e.target.value, 'contentRes')}} />
                        </div>
                    </div>
                    </>
                    :
                    <>
                    {/* 테블릿, 데스크탑 레이아웃 */}
                    <div className='info'>
                        <div className='info-img'>
                            {/* <img alt="info-img" src={contentInfo.contentExplainImg}/> */}
                            <img alt="info-img" src={showImg.contentExplainImg} onError={onErrorImg}/>
                            {/* <input id='contentExplainImg' type='file' accept = "image/*" onChange={(e) => {changeImg(e, 'contentExplainImg')}}/> */}
                            <CUSFileInput width={'100%'} id='contentExplainImg' accept = "image/*" onRemove={(e) => {changeImg(e, 'contentExplainImg')}} onChange={(e) => {changeImg(e, 'contentExplainImg')}}/>
                        </div>
                        <div className='info-text'>
                            <div className='title'>
                                <strong>서비스 설명</strong>
                                <p>{`${contentInfo.contentExplain.length}/1000`}</p>
                            </div>
                            {/* <p>{contentInfo.contentExplain}</p> */}
                            <textarea value={contentInfo.contentExplain} maxLength={1000} onChange={(e) => {changeContentInfo(e.target.value, 'contentExplain')}} />
                        </div>
                    </div>
                    <div className='result'>
                        <div className='result-text'>
                            <div className='title'>
                                <strong>서비스 결과</strong>
                                <p>{`${contentInfo.contentRes.length}/1000`}</p>
                            </div>
                            {/* <p>{contentInfo.contentRes}</p> */}
                            <textarea value={contentInfo.contentRes} maxLength={1000} onChange={(e) => {changeContentInfo(e.target.value, 'contentRes')}} />
                        </div>
                        <div className='result-img'>
                            <img alt="result-img" src={showImg.contentResImg} onError={onErrorImg}/>
                            <CUSFileInput width={'100%'} id='contentResImg' accept = "image/*" onRemove={(e) => {changeImg(e, 'contentResImg')}} onChange={(e) => {changeImg(e, 'contentResImg')}}/>
                            {/* <input id='contentResImg' type='file' accept = "image/*" onChange={(e) => {changeImg(e, 'contentResImg')}}/> */}
                        </div>
                    </div>
                    </>
                }
                </div>
                <div className='keyword'>
                    <div>
                        <input 
                            id='contentKeywordYn'
                            type='checkbox' 
                            value={contentInfo.contentKeywordYn}
                            checked={contentInfo.contentKeywordYn === 'Y' ? true : false}
                            onChange={(e) => {
                                if(e.target.checked) {
                                    changeContentInfo('Y', 'contentKeywordYn')
                                } else {
                                    changeContentInfo('N', 'contentKeywordYn')
                                }
                            }}/>
                        <label htmlFor='contentKeywordYn'>컨텐츠 사용 여부</label>
                    </div>
                    {
                        contentInfo.contentKeywordYn === 'Y' &&
                        <input 
                            type='text' 
                            placeholder='키워드 설명'
                            value={contentInfo.contentKeywordDescription}
                            onChange={(e) => {
                                changeContentInfo(e.target.value, 'contentKeywordDescription')
                            }}
                            />
                    }
                </div>
            </div>
            :
            <>
                <div className='title-area'>
                    <p className='title'>컨텐츠 관리</p>
                    <button onClick={(e) => {addContent()}}>추가</button>
                </div>
                <CUSBoxTile>
                    {
                        contents.map((e, i) => (
                            <CUSBoxItem
                                key={`contents${e.contentSeq}`} 
                                title={e.contentNm} 
                                thumbnailImg={e.contentThumbnailImg}
                                description={e.contentSimpleDescription} 
                                adminMode={true}
                                onDelete={(evt) => {deleteContent(e.contentSeq)}}
                                onEdit={(evt) => {editContent(e)}}
                                // onClick={(evt) => {moveContent(e.contentSeq)}}
                            />
                        ))
                    }
                </CUSBoxTile>
            </>
        }
        </article>
    )
}

export default ManageContent