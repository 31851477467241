import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import './Layout.scss'
import ScrollUpIco from 'static/images/scroll-up.png'
import Header from "./header/Header"
import InfoService from "component/pages/infoService/InfoService"
import WorkAutomation from "component/pages/workAutomation/WorkAutomation"
import Footer from "./footer/Footer"
import Content from "component/pages/infoService/content/Content"
import AutomationContent from "component/pages/workAutomation/content/Content"
import { useEffect, useState } from "react"
import Login from "component/pages/login/Login"
import SignUp from "component/pages/signUp/SignUp"
import MyPage from "component/pages/myPage/MyPage"
import ModifyUser from "component/pages/modifyUser/ModifyUser"
import Util from "component/util/Util"
import {Cookies} from 'react-cookie';
import AdminPage from "component/pages/admin/AdminPage"
import ErrorPage from "component/pages/errorPage/ErrorPage"
import Home from "component/pages/home/Home"
import NonMemberSubscribe from "component/pages/infoService/nonMember/NonMemberSubscribe"
import OfferBoard from "component/pages/offerBoard/OfferBoard"
import OfferBoardDetail from "component/pages/offerBoard/offerBoardDetail/OfferBoardDetail"
import OfferBoardEdit from "component/pages/offerBoard/offerBoardEdit/OfferBoardEdit"

const Layout = (props) => {    
    const cookies = new Cookies();
    const [showBtn, setShowBtn] = useState(false) // 맨 위로 올리기 버튼 표시 유무

    const [isMobile, setIsMobile] = useState(false) // 모바일 화면크기 여부

    // 스크롤 위치에 따라 맨위 올리기 버튼 표시 감지 이벤트 작성
    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 100) { // 스크롤이 100px넘어가면 표시
                setShowBtn(true)
            } else {
                setShowBtn(false)
            }
        }
        // 758px 미만일 경우 모바일.  화면 사이즈에 따른 레이아웃 변경
        const isMobileFunc = () => {
            if (window.innerWidth < 758) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
        // 화면 최초 진입시 체크
        isMobileFunc()
        window.addEventListener('resize', isMobileFunc); // 이벤트 등록

        // 스크롤 이벤트 add
        window.addEventListener("scroll", handleShowButton)
        return () => {
            window.removeEventListener("scroll", handleShowButton)
            window.removeEventListener("resize", isMobileFunc) // 이벤트 제거
        }
    }, [])

    // 맨위 올리기 버튼 누를시 이동
    const scrollToTop = () => {
        window.scrollTo({top:0, behavior:'smooth'})
    }


    /* 로그인 or 권한 있는 페이지 관리
        로그인 안됬을 경우 - 로그인페이지 이동
        권한 없을 경우 - 홈 이동
    */
    const ProtectPage = (page, useAuth) => {
        const cookie = cookies.get('sessionUser')
        if(!cookie?.sessionToken) {
            return <Navigate replace to="/login" />
        }
        if(!!useAuth && cookie?.userRole !== 'ADMIN') {
            return <Navigate replace to="/" />
        }
        return (
            <main id='main' className="main-area">
                {page}
            </main>
        )
    }
    // 일반페이지 관리
    const MainPage = (page) => {
        return (
            <main id='main' className={`main-area`}>
                {page}
            </main>
        )
    }

    return (
        <>
            <Router>
                <Header isMobile={isMobile}/>
                <Routes>
                    <Route path="/" element={ MainPage(<Home util={Util} isMobile={isMobile}/>) }/> {/* 홈 페이지 */}

                    <Route path="/info-service" element={ MainPage(<InfoService util={Util}/>) }/> {/* 정보서비스 */}
                    {/* contentSeq 키 값으로 파라미터 전달하며 페이지 이동 */}
                    <Route path="/info-service/content/:contentSeq" element={ MainPage(<Content util={Util} isMobile={isMobile}/>) }/> {/* 정보서비스 - 컨텐츠 페이지 */}

                    <Route path="/work-automation" element={ MainPage(<WorkAutomation util={Util}/>) }/> {/* 업무 자동화 */}
                    <Route path="/work-automation/content/:contentSeq" element={ MainPage(<AutomationContent util={Util} isMobile={isMobile}/>) }/> {/* 업무 자동화 - 컨텐츠 페이지 */}

                    <Route path="/offer-board/:page?" element={ MainPage(<OfferBoard util={Util} isMobile={isMobile}/>)} /> {/* 기능제안 게시판 */}
                    <Route path="/offer-board/content/:boardSeq" element={ MainPage(<OfferBoardDetail util={Util} isMobile={isMobile}/>)} /> {/* 기능제안 게시판 디테일*/}
                    <Route path="/offer-board/edit/:boardSeq?" element={ MainPage(<OfferBoardEdit util={Util} isMobile={isMobile}/>)} /> {/* 기능제안 등록/수정 디테일*/}

                    <Route path="/login" element={ MainPage(<Login util={Util}/>) }/> {/* 로그인 페이지 */}
                    <Route path="/signUp" element={ MainPage(<SignUp util={Util}/>) }/> {/* 회원가입 페이지 */}

                    {/* 로그인 되어야 이동할 수 있는 페이지들 */}
                    <Route path="/myPage/:menuNm" element={ ProtectPage(<MyPage util={Util}/>, false) }/> {/* 마이 페이지 */}
                    <Route path="/myPage/modify" element={ ProtectPage(<ModifyUser util={Util}/>, false) }/> {/* 회원정보수정 페이지 */}

                    {/* 권한이 있어야 이동할 수 있는 페이지들 */}
                    <Route path="/admin-page/:menuNm" element={ ProtectPage(<AdminPage util={Util} isMobile={isMobile}/>, true) }/> {/* 관리자 페이지 */}

                    {/* 비회원 구독 기능 페이지 */}
                    <Route path="/non-menber/:subscribeType/:token" element={ MainPage(<NonMemberSubscribe util={Util}/>)}/> {/* 비회원 구독 관리*/}

                    {/* 지정된 path 이외로 접근할경우 Home으로 이동 */}
                    <Route path="*" element={<Navigate replace to="/" />}></Route>
                    <Route path="/error" element={<ErrorPage />} />
                </Routes>
            </Router>
            {/* <Footer/> */}

            {/* 맨위 올리기 버튼 */}
            <div className={`scroll-up ${(!!showBtn && 'show-btn') || ''}`}>
                <img src={ScrollUpIco} alt='ScrollUp' onClick={scrollToTop}/>
            </div>
        </>
    )
}

export default Layout