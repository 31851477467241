import ReactQuill, { Quill } from "react-quill"
import './Editer.scss'
import { useMemo } from "react";
import { CustomToolbar } from "./CustomToolbar";
import { FontSize } from "./fontSize";

const Editer = (props) => {
    var Size = Quill.import("attributors/style/size");
    Size.whitelist = FontSize;
    Quill.register(Size, true);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: "#toolbar",
            },
            clipboard: {
                matchVisual: false
            }
        };
    }, []);
        
    return (
        <>
            <CustomToolbar/>
            <ReactQuill 
                className={'editer-style'} 
                modules={modules}
                value={props.value || ''}
                onChange={(content, delta, source, editor) => !!props.onChange && props.onChange(editor.getHTML())}/>
        </>
    )
}

export default Editer