import { useNavigate, useParams } from 'react-router-dom';
import './Content.scss'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import NormalImg from 'static/images/image_error.webp'
import Footer from 'component/layout/footer/Footer';

const Content = (props) => {
    const navigate = useNavigate()
    const [cookies] = useCookies(['sessionUser']); // 쿠키 관리 Hook
    const params = useParams(); // URL에 담겨진 파라미터 꺼내는 Hook
    // 컨텐츠 정보
    const [contentInfo, setContentInfo] = useState({
        contentSeq: '',
        contentNm: '',
        contentDetailDescription: '',
        contentExplain: '',
        contentExplainImg: '',
        contentRes: '',
        contentResImg: '',
        contentKeywordYn: 'N',
        contentKeywordDescription: ''
    })
    const [keyword, setKeyword] = useState('') // 키워드
    const [email, setEmail] = useState('') // 비회원 구독 이메일

    const [bigImg, setBigImg] = useState({open: false, src: ''}) // 사진 크게보기 여부


    // 초기 진입시 페이지 정보 호출
    useEffect(() => {
        window.scrollTo({top:0})
        getContentDetail(params.contentSeq)
    }, [params.contentSeq])
    // 컨텐츠 정보 불러오기
    const getContentDetail = async(contentSeq) => {
        try {
            const content = await props.util.callApi.Get('/content/' + contentSeq)
            // 커스텀 에러 발생시
            if(!content.data || !!content.data?.status) {
                navigate('/error', {replace:true})
                return;
            }
            setContentInfo({
                contentSeq: content.data.contentSeq,
                contentNm: content.data.contentNm || '',
                contentDetailDescription: content.data.contentDetailDescription || '',
                contentExplain: content.data.contentExplain || '',
                contentExplainImg: content.data.contentExplainImg || undefined,
                contentRes: content.data.contentRes || '',
                contentResImg: content.data.contentResImg || '',
                contentKeywordYn:content.data.contentKeywordYn || 'N',
                contentKeywordDescription: content.data.contentKeywordDescription || ''
            })
        } catch(error) {
            console.error(error)
            navigate('/error', {replace:true})
        }
    }

    // 구독 신청
    const subscribe = async(e) => {
        e.preventDefault()
        
        if(window.confirm('구독하시겠습니까?')) {
            if(!cookies.sessionUser?.sessionToken) { // 비회원 구독
                try{
                    const param = {
                        nonMemberEmail: email,
                        contentSeq: contentInfo.contentSeq,
                        nonMemberSubscribeKeyword: contentInfo.contentKeywordYn === 'Y' ? keyword : undefined
                    }
                    const result = await props.util.callApi.Post('/content/subscribe/non-member', param)
                    if(!!result.data?.status) {
                        window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                        return
                    }
                    window.alert(`이메일 유효성 검사를 위한 확인 메일이 발송되었습니다.\n입력하신 메일(${email})의 메일함을 확인해 주시길 바랍니다.`)
                    window.scrollTo({top:0})
                    window.location.reload()
                } catch(error) {
                    console.error(error)
                }
            }
            else { // 회원 구독
                try{
                    const param = {
                        contentSeq: contentInfo.contentSeq,
                        subscribeKeyword: contentInfo.contentKeywordYn === 'Y' ? keyword : undefined
                    }
                    const result = await props.util.callApi.Post('/content/subscribe', param)
                    if(!!result.data?.status) {
                        window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                        return
                    }
                    window.alert('구독 신청이 완료되었습니다.')
                    window.scrollTo({top:0})
                    window.location.reload()
                } catch(error) {
                    console.error(error)
                }
            }
        }
    }

    // 이미지 error 발생 시 대체 이미지로 이미지 설정
    const onErrorImg = (e) => {
        e.target.src = NormalImg
    }

    return (
        <article className='content'>
            <div className='title-area'>
                <strong className='title'>{contentInfo.contentNm}</strong>
                <p className='description'>{contentInfo.contentDetailDescription}</p>
            </div>
            <div className='service-area'>
                {
                    props.isMobile ? 
                    <>
                    {/* 모바일 레이아웃 */}
                    <div className='info'style={{paddingBottom:'4rem'}}>
                        <div className='info-text'>
                            <strong>서비스 설명</strong>
                        </div>
                        <div className='info-img' style={{margin: '2rem 0'}} >
                            <img alt="info-img" src={contentInfo.contentExplainImg} onClick={(e) => {setBigImg({open:true, src: contentInfo.contentExplainImg})}}/>
                            <div className='img-background' onClick={(e) => {setBigImg({open:true, src: contentInfo.contentExplainImg})}}><p>크게보기</p></div>
                        </div>
                        <div className='info-text'>
                            <p>{contentInfo.contentExplain}</p>
                        </div>
                    </div>
                    <div className='result'>
                        <div className='result-text' >
                            <strong>서비스 결과</strong>
                        </div>
                        <div className='result-img' style={{margin: '2rem 0'}}>
                            <img alt="result-img" src={contentInfo.contentResImg} onClick={(e) => {setBigImg({open:true, src: contentInfo.contentResImg})}}/>
                            <div className='img-background' onClick={(e) => {setBigImg({open:true, src: contentInfo.contentResImg})}}><p>크게보기</p></div>
                        </div>
                        <div  className='result-text'>
                            <p>{contentInfo.contentRes}</p>
                        </div>
                    </div>
                    </>
                    :
                    <>
                    {/* 테블릿, 데스크탑 레이아웃 */}
                    <div className='info'>
                        <div className='info-img'>
                            <img alt="info-img" src={contentInfo.contentExplainImg} onError={onErrorImg} />
                            <div className='img-background' onClick={(e) => {setBigImg({open:true, src: contentInfo.contentExplainImg})}}><p>크게보기</p></div>
                        </div>
                        <div className='info-text'>
                            <strong>서비스 설명</strong>
                            <p>{contentInfo.contentExplain}</p>
                        </div>
                    </div>
                    <div className='result'>
                        <div className='result-text'>
                            <strong>서비스 결과</strong>
                            <p>{contentInfo.contentRes}</p>
                        </div>
                        <div className='result-img'>
                            <img alt="result-img" src={contentInfo.contentResImg} onError={onErrorImg} />
                            <div className='img-background' onClick={(e) => {setBigImg({open:true, src: contentInfo.contentResImg})}}><p>크게보기</p></div>
                        </div>
                    </div>
                    </>
                }
            </div>
            <div className='subscription-area'>
                {/* <strong>구독하기</strong> */}
                {
                    <form className='subscription' onSubmit={(e) => {subscribe(e)}}>
                        {
                            !cookies.sessionUser?.sessionToken &&
                            <input 
                                className={'input'} 
                                id={'email'}
                                type='email'
                                required 
                                placeholder={`구독 받을 이메일`}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                />
                        }
                        {
                            contentInfo.contentKeywordYn === 'Y' &&
                            <input 
                                className={'input'} 
                                id={'keyword'} 
                                required 
                                placeholder={`${contentInfo.contentKeywordDescription}`}
                                value={keyword}
                                onChange={(e) => {
                                    setKeyword(e.target.value)
                                }}
                                />
                        }
                        <button>
                            구독하기
                        </button>
                    </form>
                }
            </div>
            {
                !!bigImg.open &&
                <div className='big-img'>
                    <div className='big-img-back' style={{cursor:'pointer'}} onClick={(e) => {setBigImg({open: false, src: ''})}}>
                        <p>X</p>
                    </div>
                    {/* <div className='big-img-area' > */}
                        <img alt="big-img" onError={onErrorImg} src={bigImg.src} />
                    {/* </div> */}
                </div>
            }
            <Footer/>
        </article>
    )
}

export default Content