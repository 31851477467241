import { useEffect, useState } from 'react'
import './Login.scss'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import { CUSTextField } from 'component/CUSComponent'
import { SHA256 } from 'crypto-js'
import axios from 'axios'

const Login = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['sessionUser']); // 쿠키 관리 Hook
    const { pathname, search, state } = useLocation(); // 페이지 주소 관리 Hook
    const [id, setId] = useState('') // 아이디
    const [pw, setPw] = useState('') // 비밀번호
    const [message, setMessage] = useState('') // 에러 메시지

    useEffect(() => {
        // 페이지 최조 진입시, 이미 로그인 돼있을경우 이전페이지 or 홈 으로 이동
        if(!!cookies.sessionUser?.sessionToken) {
            const path = state || '/'

            document.location.replace(path)
        }
    }, [])

    // 로그인 처리
    const login = async(e) => {
        e.preventDefault()
        const hash = SHA256(pw).toString(); //비밀번호 SHA256 방식으로 암호화
        const param = {
            userEmail: id,
            userPw: hash
        }

        try {
            const loginUser = await props.util.callApi.Post('/user/login', param)
            if(!!loginUser.data?.status) { // 응답데이터에 status가 있을경우 커스텀에러
                setMessage('아이디/비밀번호를 확인해주세요')
                return
            }
            setCookie('sessionUser', JSON.stringify(loginUser.data), { path: '/'}) // 쿠키에 저장
            document.location.replace(state)
        } catch (error) {
            console.error(error)
        }
    }
    // 회원가입 페이지 이동
    const moveSignUp = (e) => {
        e.preventDefault()
        document.location.replace('/signUp')
    }
    return (
        <article className='login-page'>
            <form onSubmit={(e) => {login(e)}} className='login-area'>
                <strong className='title'>BOT Service</strong>
                <strong className='description'>로그인</strong>
                <p className='message'>{message}</p>
                <CUSTextField 
                    required 
                    type='email' 
                    placeholder='아이디'
                    value={id}
                    onChange={(e) => {setId(e.target.value)}}
                />
                <CUSTextField 
                    required 
                    type='password' 
                    placeholder='비밀번호'
                    value={pw}
                    onChange={(e) => {setPw(e.target.value)}}
                />
                {/* <a href='#!' onClick={(e) => {e.preventDefault()}}>비밀번호 찾기</a> */}
                <button 
                    className='btn'
                    type='submit'
                >
                    로그인
                </button>
                <button 
                    className='btn white' 
                    onClick={(e) => {moveSignUp(e)}}
                >
                    회원가입
                </button>
            </form>
        </article>
    )
}

export default Login