import './CUSTextField.scss'

// 입력 input 컴포넌트
const CUSTextField = (props) => {
    return (
        <input 
            id={props.id || undefined}
            required = {props.required || false}
            type={props.type || 'text'}
            className='CUSTextField' 
            style={props.style}
            placeholder={props.placeholder || ''}
            maxLength={props.maxLength || undefined}
            value={props.value}
            onChange={(e) => {!!props.onChange && props.onChange(e)}}
        />
    )
}

export default CUSTextField