import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkAutomation.scss'
import { CUSBoxItem, CUSBoxTile } from "component/CUSComponent"
import AutomationData from "./data/WorkAutomationData.json"

const WorkAutomation = (props) => {
    const navigate = useNavigate();
    const [contents, setContents] = useState([]) // 정보서비스 컨텐츠 리스트

    // 최초진입시 컨텐츠 목록 조회
    useEffect(() => {
        getContents()
    }, [])

    // 컨텐츠 목록 조회 호출
    const getContents = async() => {
        try {
            const contents = AutomationData
            if(!!contents.data?.status) {
                return
            }

            setContents(contents.data)
        } catch(error) {
            console.error(error)
        }
    }
    /* 
        RPA 컨텐츠로 이동 함수. /content/'컨텐츠시퀀스'로 이동하여 
        '컨텐츠시퀀스'를 파라미터로 조회
    */
    const moveContent = (contentSeq) => {
        if(!!contentSeq) {
            navigate(`/work-automation/content/${contentSeq}`)
        }
    }


    return(
        <article className='infoService'>
            <div className="intro" >
                <strong className='title'> 업무 자동화 </strong>
                <p className='description'>
                    로봇이 당신의 업무를 대신하거나 도와줍니다. <br/>
                    개인, 소상공인의 공통적으로 하는 반복작업, 회계업무 등 미리개발된 자동화 로봇서비스를 사용하세요.
                </p>
            </div>
            <CUSBoxTile>
                {
                    contents.map((e, i) => (
                        <CUSBoxItem 
                            key={`contents${e.contentSeq}`} 
                            title={e.contentNm} 
                            thumbnailImg={e.contentThumbnailImg}
                            description={e.contentSimpleDescription} 
                            onClick={(evt) => {moveContent(e.contentSeq)}}
                        />
                    ))
                }
            </CUSBoxTile>
            
        </article>
    )
}

export default WorkAutomation