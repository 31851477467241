import { useNavigate } from 'react-router-dom'
import './ErrorPage.scss'
import ErrorBot from 'static/images/error_robot.webp'

const ErrorPage = (props) => {
    const navigate = useNavigate()
    return (
        <div className='error-page'>
            <div className='title-area'>
                <img src={ErrorBot} alt='Error'/>
                <div className='text-area'>
                    <strong className='kor'>오류</strong>
                    <strong className='en'>Error</strong>
                </div>
            </div>
            <div className='info-area'>
                <div className='text-area'>
                    <p className='kor'>{`요청하신 페이지를 처리 중에 오류가 발생하였습니다.\n서비스 이용에 불편을 드려 죄송합니다.`}</p>
                    <p className='en'>{`An error occurred while processing your requested page.\nWe apologize for the inconvenience caused by the service.`}</p>
                </div>
            </div>
            <div className='btn-area'>
                <button onClick={(e) => {navigate('/', {replace: true})}}>메인페이지</button>
                <button onClick={(e) => {navigate(-1, {replace: true})}}>이전페이지</button>
            </div>
        </div>
    )
}

export default ErrorPage