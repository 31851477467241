import './OfferBoardBtn.scss'
const OfferBoardBtn = (props) => {
    return (
        <button
            className={`offer-board-btn ${!!props.color ? props.color : ''}`}
            style={!!props.style ? {...props.style} : {}}
            onClick={(e) => {!!props.onClick && props.onClick(e)}}
            >
            {props.children}
        </button>
    )
}

export default OfferBoardBtn