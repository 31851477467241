import { useEffect, useState } from 'react'
import './SubscribeState.scss'
import SearchBtn from 'static/images/search_n.png'

const SubscribeState = (props) => {
    const [searchType, setSearchType] = useState(0) // 조회구분 (컨텐츠별 0 , 사용자별 1)
    const [orderMain, setOrderMain] = useState(0) // 구독정보 정렬 기준( 컨텐츠/사용자순 0, 구독수 내림차순 1, 구독수 오름차순 2)
    const [orderDetail, setOrderDetail] = useState(0) // 상세정보 정렬 기준( 컨텐츠/사용자순 0, 구독일 내림차순 1, 구독일 오름차순 2)
    const [selected, setSelected] = useState('') // 구독정보 선택한 로우 저장
    const [main, setMain] = useState([]) // 메인데이터(구독정보)
    const [detail, setDetail] = useState([]) // 상세데이터(상세정보) 

    // 화면 초기 진입시 메인데이터 조회
    useEffect(() => {
        searchMain()
    }, [])

    // 구독정보 선택, 상세정보 정렬기준 변경시 상세정보 재조회
    useEffect(() => {
        if(!selected) {
            return;
        }
        searchDetail()
    }, [selected, orderDetail])

    // 조회구분, 구독정보 정렬기준 변경시 구독정보 재조회
    useEffect(() => {
        searchMain()
    }, [searchType, orderMain])

    // 조회구분 변경
    const changeType = (e) => {
        setSearchType(Number(e.target.value)); 
    }

    // 구독정보 조회
    const searchMain = async() => {
        try {
            const result = await props.util.callApi.Get(`/admin/subscribe?searchType=${searchType}&orderType=${orderMain}`)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            setSelected('')
            setDetail([])
            setMain(result.data)
        } catch(error) {
            console.error(error)
        }
    }
    // 상세정보 조회
    const searchDetail = async() => {
        try {
            const result = await props.util.callApi.Get(`/admin/subscribe/${selected}?searchType=${searchType}&orderType=${orderDetail}`)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }

            setDetail(result.data)
        } catch(error) {
            console.error(error)
        }
    }

    return (
        <div className="state-page">
            <div className="state-area">
                <div className='title-area'>
                    <p className='title'>구독 현황</p>
                </div>
                <div className='search-panel'>
                    <div className='serach-items'>
                        <div className='search-item'>
                            <label htmlFor='searchType'>조회 구분</label>
                            <select id='searchType' value={searchType} onChange={(e) => {changeType(e)}}>
                                <option value={0}>컨텐츠별</option>
                                <option value={1}>사용자별</option>
                            </select>
                        </div>
                    </div>
                    <div className='search-btn'>
                        <img src={SearchBtn} alt='search' onClick={(e) => {searchMain()}}/>
                    </div>
                </div>
                <div className={`data-area`}>
                    <div className={`main-data`}>
                        <div className='title-area'>
                            <strong>{'구독정보'}</strong>
                            <span>*클릭시 상세조회</span>
                            <select id='searchType' value={orderMain} onChange={(e) => {setOrderMain(Number(e.target.value));}}>
                                <option value={0}>{searchType === 0 ? '컨텐츠순' : '사용자순'}</option>
                                <option value={1}>구독수 내림차순</option>
                                <option value={2}>구독수 오름차순</option>
                            </select>
                        </div>
                        <table>
                            <colgroup>
                                <col />
                                <col width={60}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{searchType === 0 ? '컨텐츠명' : '계정'}</th>
                                    <th>구독수</th>
                                </tr>
                                {
                                    !! main && main.length > 0 ?
                                    main.map((e,i) => (
                                        <tr key={`main${i}`} className={`data-row ${e.seq === selected ? 'selected' : ''}`} onClick={(evt) => {setSelected(e.seq || '')}}>
                                            <td>{`${e.nm}`} {e.member === 'N' ? <span className='non-member'>비회원</span> : ''}</td>
                                            <td>{e.cnt}</td>
                                        </tr>
                                    ))
                                    :
                                    <tr className='data-row'>
                                        <td colSpan={2}>조회된 내역이 없습니다</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={`detail-data`}>
                        <div className='title-area'>
                            <strong>{'상세정보'}</strong>
                            <select id='searchType' value={orderDetail} onChange={(e) => {setOrderDetail(Number(e.target.value));}}>
                                <option value={0}>{searchType === 0 ? '사용자순' : '컨텐츠순'}</option>
                                <option value={1}>구독일 내림차순</option>
                                <option value={2}>구독일 오름차순</option>
                            </select>
                        </div>
                        <table>
                            <colgroup>
                                <col width={`${props.isMobile ? 210 : 230}`}/>
                                <col width={`${props.isMobile ? 150 : ''}`} style={{minWidth:'10rem'}}/>
                                <col width={95}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{searchType === 0 ? '계정' : '컨텐츠명'}</th>
                                    <th>키워드</th>
                                    <th>구독일</th>
                                </tr>
                                {
                                    !!detail && detail.length > 0 ?
                                    detail.map((e,i) => (
                                        <tr key={`detail${i}`} className='data-row'>
                                            <td>{`${e.nm}`} {e.member === 'N' ? <span className='non-member'>비회원</span> : ''}</td>
                                            <td>{e.keyword}</td>
                                            <td>{e.dt}</td>
                                        </tr>
                                    ))
                                    : 
                                    <tr className='data-row'>
                                        <td colSpan={3}>조회된 내역이 없습니다</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeState