import axios from "axios"
import {Cookies} from 'react-cookie';
const cookies = new Cookies();
const defaultPath = '/api/v1'

const Util = {
    callApi: {
        /**
         * Get RestApi 호출
         * @param {string} path 
         * @param {object} queryParam 
         * @returns 
         */
        Get : async(path, queryParam) => {
            const sessionToken = cookies.get('sessionUser')?.sessionToken || '' // 세션에서 토큰 꺼내기. 없을경우 ''
            // 헤더 설정
            const headers = {
                sessionToken: `${sessionToken}`
            }

            try {
                const result = await axios.get(process.env.REACT_APP_API_URL + defaultPath + path , {params: queryParam, headers: headers})
                // 토큰 오류(만료 or 인증실패)시 쿠키 제거 후 login 페이지 이동
                if(!!result.data?.data?.errorCode && result.data.data.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                    return;
                }
                return result.data
            } catch(error) {
                if(error.response?.data?.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                }
                throw error
            }

        },

        /**
         * Post RestApi 호출
         * @param {string} path 
         * @param {object} param 
         * @returns 
         */
        Post : async(path, param, fileNames) => {
            const sessionToken = cookies.get('sessionUser')?.sessionToken || '' // 세션에서 토큰 꺼내기. 없을경우 ''
            // 헤더 설정
            const headers = {
                sessionToken: sessionToken
            }
            // 파일 전송시 헤더 셋팅
            if(!!fileNames) {
                headers['Content-Disposition'] = `form-data; name="file"; filename="${encodeURI(fileNames)}"`
            }
            
            try {
                const result = await axios.post(process.env.REACT_APP_API_URL + defaultPath + path, param, {headers: headers})
                // 토큰 오류(만료 or 인증실패)시 쿠키 제거 후 login 페이지 이동
                if(!!result.data?.data?.errorCode && result.data.data.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                    return;
                }
                return result.data
            } catch(error) {
                if(error.response?.data?.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                }
                throw error
            }
        },

        /**
         * PUT RestApi 호출
         * @param {string} path 
         * @param {object} param 
         * @returns 
         */
        Put : async(path, param, fileNames) => {
            const sessionToken = cookies.get('sessionUser')?.sessionToken || '' // 세션에서 토큰 꺼내기. 없을경우 ''
            // 헤더 설정
            const headers = {
                sessionToken: sessionToken
            }
            // 파일 전송시 헤더 셋팅
            if(!!fileNames) {
                headers['Content-Disposition'] = `form-data; name="file"; filename="${encodeURI(fileNames)}"`
            }

            try {
                const result = await axios.put(process.env.REACT_APP_API_URL + defaultPath + path, param, {headers: headers})
                // 토큰 오류(만료 or 인증실패)시 쿠키 제거 후 login 페이지 이동
                if(!!result.data?.data?.errorCode && result.data.data.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                    return;
                }
                return result.data
            } catch(error) {
                if(error.response?.data?.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                }
                throw error
            }
        },

        /**
         * DELETE RestApi 호출
         * @param {string} path 
         * @param {object} queryParam 
         * @returns 
         */
        Delete : async(path, queryParam) => {
            const sessionToken = cookies.get('sessionUser')?.sessionToken || '' // 세션에서 토큰 꺼내기. 없을경우 ''
            // 헤더 설정
            const headers = {
                sessionToken: sessionToken
            }

            try {
                const result = await axios.delete(process.env.REACT_APP_API_URL + defaultPath + path, {params: queryParam, headers: headers})
                // 토큰 오류(만료 or 인증실패)시 쿠키 제거 후 login 페이지 이동
                if(!!result.data?.data?.errorCode && result.data.data.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                    return;
                }
                return result.data
            } catch(error) {
                if(error.response?.data?.errorCode.indexOf('T') > -1) {
                    window.alert('세션이 종료되어 로그인페이지로 이동합니다.')
                    cookies.remove('sessionUser', {path: '/'})
                    document.location.replace('/login')
                }
                throw error
            }
        },
    }
}

export default Util