import { useEffect, useState } from 'react'
import './AdminPage.scss'
import { Link, useParams } from 'react-router-dom';
import UserPage from './userPage/UserPage';
import ManageContent from './manageContent/ManageContent';
import SubscribeState from './subscribeState/SubscribeState';

const AdminPage = (props) => {
    const params = useParams(); // URL에 담겨진 파라미터 꺼내는 Hook

    const [selected, setSelected] = useState('subscribe-state')

    useEffect(() => {
        setSelected(params.menuNm)
    }, [params.menuNm])

    return (
        <article>
        <nav className='menu-tree'>
            <div className={`menu-item ${selected === 'subscribe-state' ? 'selected' : ''}`}>
                <Link
                    to='/admin-page/subscribe-state'
                    onClick={(e) => {
                        setSelected('subscribe-state')
                    }}>
                    구독현황
                </Link>
            </div>
            <div className={`menu-item ${selected === 'content' ? 'selected' : ''}`}>
                <Link
                    to='/admin-page/content'
                    onClick={(e) => {
                        setSelected('content')
                    }}>
                    컨텐츠 관리
                </Link>
            </div>
            <div className={`menu-item ${selected === 'user' ? 'selected' : ''}`}>
                <Link
                    to='/admin-page/user'
                    onClick={(e) => {
                        setSelected('user')
                    }}>
                    사용자 관리
                </Link>
            </div>
        </nav>
        {
            selected === 'subscribe-state' &&
            <SubscribeState util={props.util} isMobile={props.isMobile}/>
        }
        {
            selected === 'content' &&
            <ManageContent util={props.util} isMobile={props.isMobile}/>
        }
        {
            selected === 'user' &&
            <UserPage util={props.util} isMobile={props.isMobile}/>
        }
        </article>
    )
}
export default AdminPage