import Logo from 'static/images/BOT_SERVICE_logo.webp'
import ArrowDown from 'static/images/arrow_down_white.png'
import MenuIco from 'static/images/menu.png'
import CloseIco from 'static/images/close.png'
import MenuDotIco from 'static/images/menu_dot_white.png'
import './Header.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useRef, useState } from 'react';
import Navi from '../navi/Navi'

const Header = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['sessionUser']);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const userInfoRef = useRef()
    const [open, setOpen] = useState(false) // 사용자 이름 클릭시 리스트 오픈여부
    const [menuOpen, setMenuOpen] = useState(false)

    // 로고 클릭시 홈 으로 이동
    const goHome = () => {
        navigate('/', {state: pathname})
    }

    // 로그인 페이지 이동
    const moveLogin = (e) => {
        e.preventDefault()
        setOpen(false)
        navigate('/login', {state: pathname})
    }
    // 회원가입 페이지 이동
    const moveSignUp = (e) => {
        e.preventDefault()
        setOpen(false)
        navigate('/signUp', {state: pathname})
    }
    // 마이페이지 이동
    const moveMyPage = (e, menuNm) => {
        e.preventDefault()
        setOpen(false)
        // userInfoRef.current?.blur()
        navigate(`/myPage/${menuNm}`, {state: pathname})
    }
    // 관리자페이지 이동
    const moveAdminPage = (e) => {
        e.preventDefault()
        setOpen(false)
        navigate(`/admin-page/subscribe-state`, {state: pathname})
    }
    // 로그아웃
    const logout = (e) => {
        e.preventDefault()
        setOpen(false)
        removeCookie('sessionUser', { path: '/' })
        navigate('/')
    }

    return (
        <header className="header" id='header'>
            <div className="header-logo">
                {
                    pathname !== '/login' && pathname !== '/signUp' &&
                    <img src={MenuDotIco} alt='menu' className='menu' onClick={(e) => {setMenuOpen(true)}}/>
                }
                <img src={Logo} alt='SICT' onClick={(e) => {goHome()}}/>
            </div>
            {
            pathname !== '/login' &&
            <div className='header-user'>
                {
                    props.isMobile ?
                    <div className='mobile-header'>
                        <img alt='menu' src={MenuIco} onClick={(e) => setOpen(true)}/>
                            {
                                !!cookies.sessionUser?.sessionToken ?
                                <div id='mobile-menu' className={`mobile-menu ${open ? 'open' : ''}`} onClick={(e) => { console.log(e); e.target.id === 'mobile-menu' && setOpen(false)}}>
                                    <div className={`menu-area ${open ? 'open' : ''}`}> 
                                        <div className='menu-header'>
                                            <img alt='X' src={CloseIco} onClick={(e) => setOpen(false)}/>
                                        </div>
                                        <div className='menu-list'>
                                            <a href='#!' onClick={(e) => {moveMyPage(e,'subscribe')}}>내 구독 목록</a>
                                            <a href='#!' onClick={(e) => {moveMyPage(e,'account')}}>계정 설정</a>
                                            {cookies.sessionUser?.userRole === 'ADMIN' &&
                                                <a href='#!' onClick={(e) => {moveAdminPage(e)}}>Back Office</a>
                                            } 
                                            <a href='#!' onClick={(e) => {logout(e)}}>로그아웃</a>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div id='mobile-menu' className={`mobile-menu ${open ? 'open' : ''}`} onClick={(e) => { console.log(e); e.target.id === 'mobile-menu' && setOpen(false)}}>
                                    <div className={`menu-area ${open ? 'open' : ''}`}> 
                                        <div className='menu-header'>
                                            <img alt='X' src={CloseIco} onClick={(e) => setOpen(false)}/>
                                        </div>
                                        <div className='menu-list'>
                                            <a href='#!' onClick={(e) => {moveSignUp(e)}}>회원가입</a>
                                            <a href='#!' onClick={(e) => {moveLogin(e)}}>로그인</a>
                                        </div>
                                    </div>
                                </div>
                            }
                    </div>
                    :
                    !!cookies.sessionUser?.sessionToken ?
                    <div 
                        className='user-info' 
                        ref={userInfoRef}
                        tabIndex={-1}
                        onBlur={(e) => {setOpen(false)}} 
                        onFocus={(e) => {setOpen(true); }}>
                        <p>{cookies.sessionUser.userNm}</p>
                        <img src={ArrowDown} alt='arrowDown' className={`${open ? 'open' : ''}`}/>
                        <div className={`user-menu ${open ? 'open' : ''}`}>
                            <a href='#!' onClick={(e) => {moveMyPage(e,'subscribe')}}>내 구독 목록</a>
                            <a href='#!' onClick={(e) => {moveMyPage(e,'account')}}>계정 설정</a>
                            {cookies.sessionUser?.userRole === 'ADMIN' &&
                                <a href='#!' onClick={(e) => {moveAdminPage(e)}}>Back Office</a>
                            } 
                            <a href='#!' onClick={(e) => {logout(e)}}>로그아웃</a>
                        </div>
                    </div>
                    :
                    <>
                    <a href='#!' onClick={(e) => {moveSignUp(e)}}>회원가입</a>
                    <a href='#!' onClick={(e) => {moveLogin(e)}}>로그인</a>
                    </>
                }
            </div>
            }
            <Navi open={menuOpen} setMenuOpen={(e) => setMenuOpen(e)}/>
        </header>
    )
}

export default Header