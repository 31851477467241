import { useNavigate, useParams } from 'react-router-dom'
import './OfferBoardEdit.scss'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import Footer from 'component/layout/footer/Footer'
import OfferBoardBtn from '../button/OfferBoardBtn'
import { SHA256 } from 'crypto-js';
import Constants from '../Constants/Constants';
import Editer from './editer/Editer';

const OfferBoardEdit = (props) => {
    const param = useParams()
    const navigate = useNavigate()
    const [cookies] = useCookies(['sessionUser']); // 쿠키 관리 Hook
    const [isNew, setIsNew] = useState(true) // 신규 등록, 수정 여부(신규 true, 수정 false)
    const [offerContent, setOfferContent] = useState({ // 게시글 정보
        boardSeq: '', // 게시글 시퀀스
        boardTitle: '', // 제목
        boardWriter: '', // 작성자 시퀀스
        boardWriterNm: '', // 작성자 이름
        boardPw: '', // 비회원 비밀번호
        boardType: '', // 기능 타입
        boardContent: '', // 내용
        boardGoodCnt: 0, // 추천 수
        boardBadCnt: 0, // 비추천 수
        boardViewCnt: 0, // 조회수
        dt: '' // 날짜
    })
    const [editerContent, setEditerContent] = useState('') // 에디터 Value

    // 최초 진입시 신규등록, 수정 체크. 수정일 경우 상세정보 호출
    useEffect(() => {
        if(!!param.boardSeq) {
            getBoardDetail(param.boardSeq)
            setIsNew(false)
        }
    }, [param.boardSeq])
    // 게시글 상세정보 호출
    const getBoardDetail = async(boardSeq) => {
        try {
            const result = await props.util.callApi.Get(`/board/offer/${boardSeq}`)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const offerContent = result.data
            if(offerContent.offerDetail.owner !== 'Y') {
                window.alert('게시글 수정 권한이 없습니다.')
                navigate('/offer-board')
            }
            setOfferContent(offerContent.offerDetail)
            setEditerContent(offerContent.offerDetail.boardContent)

        } catch(error) {
            console.error(error)
        }
    }
    // offerContent setState
    const changeContent = (value, key) => {
        let temp = {...offerContent}
        temp[key] = value
        setOfferContent(temp)
    }

    // 제목, 내용 유효성 검사
    const checkContent = (text) => {
        if((text || '').replaceAll(' ', '').replaceAll('\r\n').length < 1) {
            return false
        }
        return true
    }
    // 등록
    const addOffer = async() => {
        if(!offerContent.boardType) {
            window.alert('기능을 선택해주세요.')
            return;
        }
        if(!checkContent(offerContent.boardTitle)) {
            window.alert('제목을 입력해주세요.')
            return;
        }
        if(!checkContent(editerContent)) {
            window.alert('내용을 입력해주세요.')
            return;
        }
        if(!cookies.sessionUser?.sessionToken && (offerContent?.boardPw || '').length < 4) {
            window.alert('비밀번호를 확인해주세요.')
            return;
        }
        try {
            const pw = SHA256(offerContent.boardPw).toString(); //비밀번호 SHA256 방식으로 암호화
            const param = {
                boardTitle: offerContent.boardTitle,
                boardPw: pw,
                boardType: offerContent.boardType,
                boardContent: editerContent || offerContent.boardContent
            }
            const result = await props.util.callApi.Post(`/board/offer`, param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            // window.alert('등록 되었습니다.')
            navigate(`/offer-board/content/${result.data}`, {replace: true})
        } catch(error) {
            console.error(error)
        }
    }

    // 수정
    const updateOffer = async() => {
        if(!offerContent.boardType) {
            window.alert('기능을 선택해주세요.')
            return;
        }
        if(!checkContent(offerContent.boardTitle)) {
            window.alert('제목을 입력해주세요.')
            return;
        }
        if(!checkContent(editerContent)) {
            window.alert('내용을 입력해주세요.')
            return;
        }
        if(offerContent.boardWriter === 0 && (offerContent?.boardPw || '').length < 4) {
            window.alert('비밀번호를 확인해주세요.')
            return;
        }
        try {
            const pw = !!offerContent.boardPw ? SHA256(offerContent.boardPw).toString() : undefined; //비밀번호 SHA256 방식으로 암호화
            const param = {
                boardSeq: offerContent.boardSeq,
                boardTitle: offerContent.boardTitle,
                boardPw: pw,
                boardType: offerContent.boardType,
                boardContent: editerContent || offerContent.boardContent
            }
            const result = await props.util.callApi.Put(`/board/offer`, param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            // window.alert('수정 되었습니다.')
            navigate(`/offer-board/content/${ offerContent.boardSeq}`, {replace: true})
        } catch(error) {
            console.error(error)
        }
    }

    // 취소
    const cancelEdit = () => {
        if(window.confirm('수정한 내역이 저장되지 않습니다.\n취소하시겠습니까?')) {
            if(!!param.boardSeq) {
                navigate(`/offer-board/content/${param.boardSeq || ''}`, {replace: true})
            } else {
                navigate(-1)
            }
        }
    }
    return (
        <article className='offer-edit-page'>
            <div className='header-area'>
                <strong>글쓰기 - 기능제안 게시판</strong>
            </div>
            <div className='offer-edit-area'>
                <div className='title-area'>
                    <div className='title'>
                        <p>기능</p>
                        <select value={offerContent.boardType || ''} onChange={(e) => {changeContent(e.target.value, 'boardType')}}>
                            <option value="" disabled hidden>선택해주세요</option>
                            {
                                Constants.contentTypeList.map((e,i) => (
                                    <option key={`typeOption${i}`} value={e.value}>{e.labelText}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='title'>
                        <p>제목</p>
                        <input type='text' value={offerContent.boardTitle} onChange={(e) => {changeContent(e.target.value, 'boardTitle')}}/>
                    </div>
                </div>
                <div className='content-area'>
                    {
                        // props.isMobile ?
                        // <textarea value={offerContent.boardContent} onChange={(e) => {changeContent(e.target.value, 'boardContent')}}></textarea>
                        // :
                        // <textarea value={offerContent.boardContent} onChange={(e) => {changeContent(e.target.value, 'boardContent')}}></textarea>
                        <Editer value={editerContent} onChange={(val) => {setEditerContent(val)}}/>
                    }
                </div>
            </div>
            {
            (!cookies.sessionUser?.sessionToken || offerContent.boardWriter === 0)&&
            <div className='non-member-pw'>
                {
                    isNew ?
                    <p>*게시글 수정, 삭제 시 필요합니다. (4자리 이상 16자리 미만)</p>
                    :
                    <p>*게시글 등록 시 설정한 비밀번호를 입력해 주세요</p>
                }
                <input 
                    type='password'
                    maxLength={15}
                    onChange={(e) => {changeContent(e.target.value, 'boardPw')}}
                    placeholder='비회원 비밀번호'/>
            </div>
            }
            <div className='btn-area'>
                {
                    isNew ?
                    <OfferBoardBtn onClick={(e) => {addOffer()}}>등록</OfferBoardBtn>
                    :
                    <>
                    <OfferBoardBtn onClick={(e) => {updateOffer()}}>저장</OfferBoardBtn>
                    </>
                }
                <OfferBoardBtn color={'white'} onClick={(e) => { cancelEdit() }}>취소</OfferBoardBtn>
            </div>
            <Footer />
        </article>
    )
}
export default OfferBoardEdit