import { useNavigate } from 'react-router-dom'
import './SubscribeCard.scss'

const SubscribeCard = (props) => {
    const navigate = useNavigate()
    /* 
        RPA 과제로 이동 함수. /content/'과제시퀀스'로 이동하여 
        '과제시퀀스'를 파라미터로 조회
    */
    const moveContent = (contentSeq) => {
        if(!!contentSeq) {
            navigate(`/info-service/content/${contentSeq}`)
        }
    }

    const unsubscribe = async(subscribeSeq) => {
        if(!!subscribeSeq) {
            if(window.confirm('구독을 해지하시겠습니까?')) {
                try{
                    const result = await props.util.callApi.Delete('/content/subscribe/' + subscribeSeq)
                    if(!!result.data?.status) {
                        window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                        return;
                    }
                    // window.alert('해지되었습니다.')
                    window.location.reload()
                } catch(error) {
                    console.error(error)
                }
            }
        }
    }

    return (
        <div className='subscribe-box'>
            <p className='title'>{props.title || ''}</p>
            <p className='description'>{props.description || ''}</p>
            <p className='keyword'>{!!props.keyword ? `키워드: ${props.keyword}` : ''}</p>
            <div className='btn-box'>
                <button className='detail-btn' onClick={(e) => {moveContent(props.contentSeq)}}>상세보기</button>
                <button className='cancel-btn' onClick={(e) => {unsubscribe(props.subscribeSeq)}}>해지</button>
            </div>
        </div>
        
    )
}

export default SubscribeCard