import { useNavigate } from 'react-router-dom'
import './Navi.scss'
import MenuDotIco from 'static/images/menu_dot.png'
import Home from 'static/images/home.png'
import Robot1 from 'static/images/robot1.png'
import RobotShare from 'static/images/Robot_Share.webp'
import WorkAutomatic from 'static/images/work_automatic.webp'
import OfferBoard from 'static/images/board2.png'

const Navi = (props) => {
    const navigate = useNavigate()

    // 페이지 이동 후 메뉴 닫기
    const movePage =(page) => {
        navigate(page)
        props.setMenuOpen(false)
    }
    return (
        <div className={`navi-background ${!!props.open ? 'open' : ''}`} id='navi-background' onClick={(e) => {e.target.id === 'navi-background' && !!props.setMenuOpen && props.setMenuOpen(false)}}>
            <nav className={`navi-area ${!!props.open ? 'open' : ''}`}>
                <div className='header'>
                    <img src={MenuDotIco} alt='menu' className='menu' onClick={(e) => {!!props.setMenuOpen && props.setMenuOpen(false)}}/>
                </div>
                <div className='menu-area'>
                    <div className='menu' id='newHome' onClick={(e) => {movePage('/')}}> 
                        <img alt='Home' src={Home}/>
                        <p>홈</p>
                    </div>
                    <div className='menu' id='infoService' onClick={(e) => {movePage('/info-service')}}> 
                        <img alt='Home' src={Robot1}/>
                        <p>정보 서비스</p>
                    </div>
                    <div className='menu' id='R' onClick={(e) => {movePage('/work-automation')}}> 
                        <img alt='Home' src={WorkAutomatic}/>
                        <p>업무 자동화</p>
                    </div>
                    <div className='menu' id='offerBoard' onClick={(e) => {movePage('offer-board')}}> 
                        <img alt='Home' src={OfferBoard}/>
                        <p>기능제안 게시판</p>
                    </div>
                    {/* <div className='menu' id='R' onClick={(e) => {movePage('#')}}> 
                        <img alt='Home' src={RobotShare}/>
                        <p>Robot 공유 (준비중)</p>
                    </div> */}
                </div>
            </nav>
        </div>
    )
}

export default Navi