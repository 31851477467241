import './CUSBoxItem.scss'

/**
 * 컨텐츠 카드 컴포넌트
 * @param {string} props 
 * @returns 
 */
const CUSBoxItem = (props) => {
    return (
        <div className="boxItem" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${props.thumbnailImg})`}}>
            <div className='boxItem-info'>
                <p className="title">{props.title || 'TITLE'}</p>
                <p className="description">{props.description || 'description'}</p>
            </div>
            {
                !!props.adminMode ?
                <div className='btn-admin-area'>
                    <button className="btn-admin delete" onClick={(e) => {!!props.onDelete && props.onDelete(e)}}>삭제</button>
                    <button className="btn-admin" onClick={(e) => {!!props.onEdit && props.onEdit(e)}}>수정</button>
                </div>
                :
                <button className="btn" onClick={(e) => {!!props.onClick && props.onClick(e)}}>상세보기</button>
            }
        </div>
    )
}

export default CUSBoxItem