import { useNavigate, useParams } from 'react-router-dom'
import './OfferBoardDetail.scss'
import Like from 'static/images/like.png'
import DisLike from 'static/images/dislike.png'
import CloseIco from 'static/images/close.png'
import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie';
import Footer from 'component/layout/footer/Footer'
import OfferBoardBtn from '../button/OfferBoardBtn'
import { SHA256 } from 'crypto-js'
import Constants from '../Constants/Constants'
import DOMPurify from 'dompurify'
import ReactQuill from 'react-quill'

const OfferBoardDetail = (props) => {
    const param = useParams()
    const navigate = useNavigate()
    const [cookies] = useCookies(['sessionUser']); // 쿠키 관리 Hook
    const replyRef = useRef() // 댓글 입력 textarea Ref
    const replyEditRef = useRef() // 댓글 수정 textarea Ref
    const [offerContent, setOfferContent] = useState({ // 게시글 정보
        boardSeq: '', // 게시글 시퀀스
        boardTitle: '', // 제목
        boardWriter: '', // 작성자 시퀀스
        boardWriterNm: '', // 작성자 이름
        boardType: '', // 기능 타입
        boardContent: '', // 내용
        boardGoodCnt: 0, // 추천 수
        boardBadCnt: 0, // 비추천 수
        boardViewCnt: 0, // 조회수
        dt: '', // 날짜
        replyCnt: 0 // 댓글 수
    })
    const [replyList, setReplyList] = useState([]) // 댓글 리스트
    const [replyContent, setReplyContent] = useState('') // 댓글 입력 textarea Value
    const [replyEdit, setReplyEdit] = useState('') // 댓글 수정 중인 댓글 seq
    const [replyEditText, setReplyEditText] = useState('')  // 댓글 수정 textarea Value
    const [boardPw, setBoardPw] = useState('') // 게시글 비회원 비밀번호
    const [replyPw, setReplyPw] = useState('') // 댓글 수정 비회원 비밀번호
    const [replyAddPw, setReplyAddPw] = useState('') // 댓글 등록 비회원 비밀번호
    const [nonMemDelDlg, setNonMemDelDlg] = useState(false) // 비회원 게시글 삭제시 다이얼로그 open 여부

    // 댓글 수정 버튼 누를시 높이 자동조절, 비밀번호 입력창 초기화
    useEffect(() => {
        if(!!replyEdit) {
            handleResizeHeight(replyEditRef); 
            setReplyPw('')
        }
    }, [replyEdit])

    // 페이지 최초 진입시 스크롤 상단이동, 게시글 정보 조회
    useEffect(() => {
        window.scrollTo({top:0})
        getBoardDetail(param.boardSeq)
    }, [param.boardSeq])
    
    // 게시판 상세조회
    const getBoardDetail = async(boardSeq) => {
        try {
            const result = await props.util.callApi.Get(`/board/offer/${boardSeq}`)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const offerContent = result.data
            setOfferContent(offerContent.offerDetail)
            setReplyList(offerContent.reply)

        } catch(error) {
            console.error(error)
        }
    }
    // 추천/비추천
    const addGoodBadCnt = async(key) => {
        const param = {
            boardSeq: offerContent.boardSeq
        }

        try {
            const result = await props.util.callApi.Put(`/board/offer/${key}`, param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const cnt = result.data
            let temp = {...offerContent}
            if(key === 'good') {
                temp['boardGoodCnt'] = cnt
            } else {
                temp['boardBadCnt'] = cnt
            }
            setOfferContent(temp)
        } catch(error) {
            console.error(error)
        }
    }
    // 게시글 삭제
    const deleteOffer = async() => {
        if(offerContent.boardWriter === 0 && (boardPw || '').length < 4) {
            window.alert('비회원 비밀번호를 확인해주세요.')
            return;
        }
        if(window.confirm('게시글을 삭제하시겠습니까?\n삭제 후 복구가 불가능합니다.')) {
            const pw = !!boardPw ? SHA256(boardPw).toString() : undefined; //비밀번호 SHA256 방식으로 암호화
            try {
                const result = await props.util.callApi.Delete(`/board/offer?boardSeq=${offerContent.boardSeq}${!!pw ? `&boardPw=${pw}` : ''}`)
                if(!!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    return;
                }
                
                // window.alert('게시글이 삭제되었습니다.')
                navigate('/offer-board')
            } catch(error) {
                console.error(error)
            }
        }
    }

    // 댓글 textarea 자동 높이 조절
    const handleResizeHeight = (ref) => {
        ref.current.style.height = 'auto'; //height 초기화
        ref.current.style.height = ref.current.scrollHeight + 'px';
    };
    // 댓글 등록
    const addReply = async() => {
        if((replyContent || '').replaceAll(' ', '').length < 1) {
            window.alert('댓글 내용을 입력해주세요.')
            return
        }
        if(!cookies.sessionUser?.sessionToken && (replyAddPw || '').length < 4) {
            window.alert('비밀번호를 확인해주세요.')
            return;
        }
        const pw = SHA256(replyAddPw).toString(); //비밀번호 SHA256 방식으로 암호화
        const param = {
            boardSeq: offerContent.boardSeq,
            replyPw: pw,
            replyContent: replyContent
        }

        try {
            const result = await props.util.callApi.Post(`/board/offer/reply`, param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            getBoardDetail(offerContent.boardSeq)
            setReplyContent('')
            setReplyAddPw('')
            // window.alert('댓글이 등록되었습니다.')

        } catch(error) {
            console.error(error)
        }

    }
    // 댓글 수정
    const updateReply = async(replySeq, replyWriter) => {
        if((replyEditText || '').replaceAll(' ', '').length < 1) {
            window.alert('댓글 내용을 입력해주세요.')
            return
        }

        if(replyWriter === 0 && (replyPw || '').length < 4) {
            window.alert('비회원 비밀번호를 확인해주세요.')
            return;
        }

        try {
            const pw = !!replyPw ? SHA256(replyPw).toString() : undefined; //비밀번호 SHA256 방식으로 암호화
            const param = {
                replySeq: replySeq,
                replyPw: pw,
                replyContent: replyEditText
            }

            const result = await props.util.callApi.Put(`/board/offer/reply`, param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            getBoardDetail(offerContent.boardSeq)
            setReplyEdit('')
            // window.alert('댓글이 수정되었습니다.')

        } catch(error) {
            console.error(error)
        }

    }
    // 댓글 삭제
    const deleteReply = async(replySeq, replyWriter) => {
        if(replyWriter === 0 && (replyPw || '').length < 4) {
            window.alert('비회원 비밀번호를 확인해주세요.')
            return;
        }

        if(window.confirm('댓글을 삭제하시겠습니까?\n삭제 후 복구가 불가능합니다.')) {
            const pw = !!replyPw ? SHA256(replyPw).toString() : undefined; //비밀번호 SHA256 방식으로 암호화
            try {
                const result = await props.util.callApi.Delete(`/board/offer/reply?replySeq=${replySeq}${!!pw ? `&replyPw=${pw}` : ''}`)
                if(!!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    return;
                }
                
                getBoardDetail(offerContent.boardSeq)
                // window.alert('댓글이 삭제되었습니다.')
            } catch(error) {
                console.error(error)
            }
        }
    }
    return (
        <article className='offer-detail-page'>
            <div className='header-area'>
                <strong>기능제안 게시판</strong>
                <div className='btn-area'>
                    <OfferBoardBtn onClick={(e) => {navigate('/offer-board')}} >목록으로</OfferBoardBtn>
                    <div style={{marginLeft: 'auto'}}>

                    <OfferBoardBtn labelText={''} onClick={(e) => {navigate('/offer-board/edit')}}>제안하기</OfferBoardBtn>
                    {
                        offerContent.owner === 'Y' &&
                    <>
                        <OfferBoardBtn color={'white'} onClick={(e) => {navigate(`/offer-board/edit/${offerContent.boardSeq}`)}}>수정</OfferBoardBtn>
                        {offerContent.boardWriter === 0 ?
                        <OfferBoardBtn color={'white'} onClick={(e) => {setNonMemDelDlg(true)}}>삭제</OfferBoardBtn>
                        :
                        <OfferBoardBtn color={'white'} onClick={(e) => {deleteOffer()}}>삭제</OfferBoardBtn>
                        }
                        {/* <input 
                        type='password'
                        maxLength={15}
                        value={boardPw}
                        onChange={(e) => {setBoardPw(e.target.value)}}
                        placeholder='비회원 삭제 비밀번호'/>} */}
                    </>
                    }
                    </div>
                </div>
            </div>
            <div className='offer-detail-area'>
                <div className='title-area'>
                    <div className='title'>
                        <p>{`[${Constants.getTypeLabel(offerContent.boardType)}] `}{offerContent.boardTitle} <span style={{fontSize: '1.6rem', color: '#5f5f5f'}}>{!!offerContent.replyCnt ? `[${offerContent.replyCnt}]` : ''}</span></p>
                        <div className='writer-area'>
                            <p className='writer'>{offerContent.boardWriterNm}</p>
                            <p className='write-dt'>{offerContent.dt}</p>
                        </div>
                        <div className='type-area'>
                            <p></p>
                        </div>
                    </div>
                    {!props.isMobile &&
                    <div className='btn-area'>
                        <div onClick={(e) => {addGoodBadCnt('good')}}>
                            <img src={Like} alt='like'/>
                            <p >추천 {offerContent.boardGoodCnt}</p>
                        </div>
                        <div onClick={(e) => {addGoodBadCnt('bad')}}>
                            <img src={DisLike} alt='dislike'/>
                            <p >비추천 {offerContent.boardBadCnt}</p>
                        </div>
                    </div>
                    }
                </div>
                <div className='content-area' >
                    {/* dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(offerContent.boardContent),
                       }}> */}
                    
                    {/* <p>{offerContent.boardContent}</p> */}
                    <ReactQuill 
                        className={'read-editer-style'}
                        theme="bubble" 
                        value={offerContent.boardContent} 
                        modules={
                            {
                                clipboard: {
                            matchVisual: false
                        }}}
                        readOnly={true} />
                </div>
                {props.isMobile &&
                <div className='title-area'>
                    <div className='btn-area' style={{marginRight: 'auto'}}>
                        <div onClick={(e) => {addGoodBadCnt('good')}}>
                            <img src={Like} alt='like'/>
                            <p >추천 {offerContent.boardGoodCnt}</p>
                        </div>
                        <div onClick={(e) => {addGoodBadCnt('bad')}}>
                            <img src={DisLike} alt='dislike'/>
                            <p >비추천 {offerContent.boardBadCnt}</p>
                        </div>
                    </div>
                </div>
                }
                <div className='reply-area'>
                    <div className='reply-content-area'>
                        <div className='offer-info'>
                            <p>댓글 {<span style={{fontWeight: 500}}>{offerContent.replyCnt}</span>}개</p>
                            <p>조회수 {<span style={{fontWeight: 500}}>{offerContent.boardViewCnt}</span>}</p>
                        </div>
                        {
                            replyList.map((e,i) => (
                                <div key={`reply-content${i}`} className='reply-content'>
                                    <div className='reply-writer'>
                                        <p className='writer'>{e.replyWriterNm}</p>
                                        <p className='write-dt'>{e.dt}</p>
                                    </div>
                                    <div className='reply-main'>
                                        {
                                            replyEdit === e.replySeq ?
                                            <div className='reply-edit'>
                                                <textarea 
                                                    ref={replyEditRef} 
                                                    rows={1} 
                                                    value={replyEditText} 
                                                    onChange={(e) => {
                                                        handleResizeHeight(replyEditRef); 
                                                        setReplyEditText(e.target.value)
                                                    }}/>
                                                {e.replyWriter === 0 &&
                                                    <input 
                                                    type='password'
                                                    style={{marginRight: '1rem'}}
                                                    maxLength={15}
                                                    value={replyPw}
                                                    onChange={(e) => {setReplyPw(e.target.value)}}
                                                    placeholder='비회원 비밀번호'/>
                                                }
                                                <OfferBoardBtn onClick={(evt) => {updateReply(e.replySeq, e.replyWriter)}}>수정</OfferBoardBtn>
                                                {e.replyWriter === 0 &&
                                                <OfferBoardBtn onClick={(evt) => {deleteReply(e.replySeq, e.replyWriter) }}>삭제</OfferBoardBtn>
                                                }
                                                <OfferBoardBtn color={'white'} onClick={(evt) => {setReplyEdit('')}}>취소</OfferBoardBtn>
                                            </div>
                                            :
                                            <p>{e.replyContent}</p>
                                        }
                                        {
                                        (e.owner === 'Y' && replyEdit !== e.replySeq) &&
                                        <div className='btn'>
                                            {e.replyWriter === 0 ?
                                            <OfferBoardBtn onClick={(evt) => { setReplyEdit(e.replySeq); setReplyEditText(e.replyContent); }}>수정/삭제</OfferBoardBtn>
                                            :
                                            <>
                                                <OfferBoardBtn onClick={(evt) => { setReplyEdit(e.replySeq); setReplyEditText(e.replyContent); }}>수정</OfferBoardBtn>
                                                <OfferBoardBtn color={'white'} onClick={(evt) => { deleteReply(e.replySeq) }}>삭제</OfferBoardBtn>
                                            </>
                                            }
                                        </div> 
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='reply-input'>
                        <textarea 
                            ref={replyRef} 
                            rows={1} 
                            value={replyContent}
                            onChange={(e) => {
                                handleResizeHeight(replyRef)
                                setReplyContent(e.target.value)
                            }} 
                            placeholder="댓글을 남겨보세요"/>
                        {!cookies.sessionUser?.sessionToken &&
                        <input 
                        type='password'
                        style={{marginRight: '1rem'}}
                        maxLength={15}
                        value={replyAddPw}
                        onChange={(e) => {setReplyAddPw(e.target.value)}}
                        placeholder='비회원 비밀번호'/>
                        }
                        <OfferBoardBtn onClick={(e) => {addReply()}}>등록</OfferBoardBtn>
                    </div>
                </div>
            </div>
            <Footer />
            {
                nonMemDelDlg &&
            <div className='non-member-delete-dlg'>
                <div className='close'>
                    <img alt='X' src={CloseIco} onClick={(e) => {setNonMemDelDlg(false)}}/>
                </div>
                <div className='title'>
                    <strong>비회원 삭제 비밀번호 확인</strong>
                </div>
                <form onSubmit={(e) => {e.preventDefault(); deleteOffer();}}>
                    <input 
                    type='new-password'
                    maxLength={15}
                    value={boardPw}
                    onChange={(e) => {setBoardPw(e.target.value)}}
                    placeholder='비회원 비밀번호'/>
                    <OfferBoardBtn onClick={(e) => {}}>삭제</OfferBoardBtn>
                </form>
            </div>
            }
        </article>
    )
}

export default OfferBoardDetail