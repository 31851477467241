import './CUSBoxTile.scss'

// 카드 그리드 형태로 렌더링 하는 컴포넌트
const CUSBoxTile = (props) => {
    return (
        <div className= {`box-tile ${props.className || ''}`}>
            {props.children}
        </div>
    )
}

export default CUSBoxTile