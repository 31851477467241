import { useRef, useState } from 'react'
import './CUSFileInput.scss'

// 파일 업로드 컴포넌트
const CUSFileInput = (props) => {
    const inputRef = useRef()
    const [fileInfo, setFileInfo] = useState('') // 파일정보

    // 파일 업로드시 파일 정보 setState
    const changeFile = (e) => {
        const data = e.target.value;
        const fileName = data.split('\\');
        setFileInfo(fileName[fileName.length - 1])
    }
    // props.width 적용
    const getWidth = () => {
        const style = !!props.width ? {width: props.width} : {}
        return style
    }
    return (
        <div className="CUSFileInput" style={getWidth()}>
            <p className='file-info'>{fileInfo}</p>
            <div className='btn-area'>
                {
                    !!fileInfo &&
                    <a 
                        className='remove-file' 
                        href='#!' 
                        onClick={(e) => {
                            e.preventDefault();
                            setFileInfo(undefined);
                            !!props.onRemove && props.onRemove(inputRef.current)
                        }}>
                    제거
                    </a>
                }
                <label className="upload-btn" htmlFor={!!props.id && props.id}>업로드</label>
            </div>
            <input 
                ref={inputRef}
                id={!!props.id && props.id} 
                type="file" 
                accept={!!props.accept && props.accept} 
                placeholder="첨부파일"
                onChange={(e) => {
                    changeFile(e)
                    !!props.onChange && props.onChange(e)
                }}
                style={{display:'none'}}
            />
        </div>
    )
}

export default CUSFileInput