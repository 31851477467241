import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

/**
 * 비회원 구독 승인, 해지를 위한 페이지
 */
const NonMemberSubscribe = (props) => {
    const param = useParams() // URL에 path param 꺼내는 Hook
    const navigate = useNavigate()
    
    // 초기 진입시 파라미터를 가지고 API Call
    useEffect(() => {
        const type = param.subscribeType
        const token = param.token
        call(type, token)
    }, [param])
    
    const call = async(type, token) => {
        if(type === 'subscribe') { // 구독 승인
            try {
                const result = await props.util.callApi.Put('/content/subscribe/non-member/' + token)
                // 커스텀 에러 발생시
                if(!!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    navigate('/')
                    return;
                }
                alert('구독 되었습니다.')
                window.close()
                navigate('/')
            } catch(error) {
                console.error(error)
                navigate('/error', {replace:true})
            }
        }
        else if (type === 'unsubscribe') { // 구독 해지
            try {
                const result = await props.util.callApi.Delete('/content/subscribe/non-member/' + token)
                // 커스텀 에러 발생시
                if(!!result.data?.status) {
                    window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                    navigate('/')
                    return;
                }
                alert('구독 취소 되었습니다.')
                window.close()
                navigate('/')
            } catch(error) {
                console.error(error)
                navigate('/error', {replace:true})
            }
        }
    }
    return (
        <div>
        </div>
    )
}

export default NonMemberSubscribe